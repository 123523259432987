import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Accordions from "./service/Accordions";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";


const ConsultencyBlockNext = ({offset, revers, padding, data}) => {



    return (
        <StyledBuilHome offset={offset} className={`BuilHome ${padding ? padding : 'pb-180'}`}>
            <div className="noise"></div>
            <Container>
                <Row>
                    <Col md={5} className={'d-flex'}>
                        <ul>
                            {
                                data?.posts?.list && data?.posts?.list?.length>0 &&
                                data?.posts?.list?.map((e, i)=>{
                                    return(
                                        <li key={i}>
                                            <img src={e?.images[0]?.full_path} alt=""/>
                                            <p>{reactHtmlParser(e?.data?.title)}</p>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Col>
                    <Col md={{span: 5, offset: 2}} className={'box'}>
                        <div className="img-wrapper reveal">
                            <Img src={data?.images?.list[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledBuilHome>
    )
};

const StyledBuilHome = styled.div`
  position: relative;
  background: #26201E;
  z-index: 9;
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.1;
    }


  }

  .img-wrapper {
    position: relative;
    padding-top: calc(610 / 470 * 100%);
  }
  .d-flex{
    display: flex;
    align-items: center;
  }
  ul {
    li {
      color: #F1EEE9;
      font-size: 20px;
      line-height: 32px;
      display: flex;
      margin-bottom: 40px;
      &:last-child{
        margin-bottom: 0;
      }
      img{
        margin-right: 70px;
      }
    }
  }
  
  
  @media(max-width: 767px){
    .col-md-5.offset-md-2{
      padding-left: 0;
      padding-right: 0;
    }
    .img-wrapper{
      margin-top: 0;
      margin-bottom: 40px;
    }
    .row{
      flex-direction: column-reverse;
    }
    ul{
      li{
        display: flex;
        align-items: center;
        p{
          margin: 0;
        }
        img{
          margin-right: 40px;
        }
      }
    }
  }

`;


export default React.memo(ConsultencyBlockNext);
