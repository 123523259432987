import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "./SubTitle";
import Title from "./Title";

const Overview = ({data, bgcolor}) => {
    return (
        <StyledComponent  className='about-partex pt-160 pb-160' bgcolor={bgcolor}>
            <Container >
                <Row>
                    <svg xmlns="http://www.w3.org/2000/svg" width="277" height="587.997" viewBox="0 0 277 587.997">
                        <g id="Group_21926" data-name="Group 21926" transform="translate(1526 -3833)">
                            <rect id="Rectangle_5751" data-name="Rectangle 5751" width="17.312" height="449.645" transform="translate(-1396.158 3833)" fill="#e2e2e2" opacity="0.1"/>
                            <path id="Union_7" data-name="Union 7" d="M86.561,406.408H0V0H17.311V389.116h69.25V0h17.311V406.408Z" transform="translate(-1439.438 3928.117)" fill="#e2e2e2" opacity="0.1"/>
                            <path id="Union_6" data-name="Union 6" d="M173.121,354.531H0V0H17.311V337.231H173.121V0h17.311V354.531Z" transform="translate(-1482.719 4023.234)" fill="#e2e2e2" opacity="0.1"/>
                            <path id="Union_5" data-name="Union 5" d="M259.682,311.293H0V0H17.311V294H259.682V0H277V311.293Z" transform="translate(-1526 4109.705)" fill="#e2e2e2" opacity="0.1"/>
                        </g>
                    </svg>

                    <Col sm={12}>
                        <SubTitle text={'Our Story'}/>
                        <Title text={'Navigating the <span>Real Estate<br> \n' +
                            'Landscape</span> with Confidence'}/>
                    </Col>

                    <Col lg={{span:10, offset:2}}>
                        <p className={'split-up desc-top'}>Lanco Development is a reputable real estate development company known for
                            its commitment to quality and innovation.
                            With a strong focus on creating exceptional residential and commercial properties,
                            Lanco Development Lanco Development is a reputable real estate development company known for
                            its commitment to quality and innovation. With a strong focus on creating exceptional residential
                            and commercial properties, Lanco Development </p>
                        <p className={'split-up desc-bellow'}>Lanco Development is a reputable real estate development company known for
                            its commitment to quality and innovation.
                            With a strong focus on creating exceptional residential and commercial properties,
                            Lanco Development Lanco Development is a reputable real estate development company known for
                            its commitment to quality and innovation. With a strong focus on creating exceptional residential
                            and commercial properties, Lanco Development </p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: ${props => props.bgcolor || "#FFFFFF"};
  
  .row {
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 588px;
      width: 277px;
    }
  }
  
  .desc-top{
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    @media(max-width: 767px){
      margin-top: 0;
    }
  }
  
`;

export default Overview;
