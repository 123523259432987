import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TeamBox from "./TeamBox";

const Peoples = (data) => {


    return (
        <StyledComponent className={'our-team pt-180 '}>
            <Container>
                <Row>
                    <Col md={3} className={'flot-col'}>
                        <p className={'box'}>our</p>

                        <p className={'boxr'}>peoples</p>
                    </Col>
                    <Col md={{span: 9}}>
                        <Row>
                            {
                                data?.data?.posts?.list?.length > 0 &&
                                data?.data?.posts?.list?.map((e, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <TeamBox data={e} title={e?.data?.subtitle} des={e?.data?.short_desc}
                                                     img={e?.images?.[0]?.full_path}/>
                                        </Col>
                                    )
                                })

                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #f1eee9;
  position: relative;
  padding-bottom: 100px;


  .flot-col {
    p {
      font-size: 100px;
      letter-spacing: 1.5px;
      line-height: 100px;
      font-weight: 300;
      margin: 0;
      text-transform: uppercase;

      &:first-child {
        transform: rotate(-90deg);
        position: absolute;
        top: 30%;
        left: -35px;
      }

      &:last-child {
        transform: rotate(-90deg);
        position: absolute;
        top: 155px;
        right: -100px;

      }
    }
  }

  .team-box {
    margin-bottom: 80px;
  }


  @media (min-width: 1920px) {
    .flot-col {
      p {
        &:last-child {
          right: 0 !important;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .flot-col {
      p {
        font-size: 80px;
        line-height: 80px;
      }
    }
  }
`;

export default React.memo(Peoples);
