import React,{useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import Button from "./Button";
import ModalOverview from "./ModalOverview";

const OverviewInner = ({title, subtitle, description, pop}) => {
    // const overview_data = data?.data?.section_data;

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }

    return (
        <StyledOverviewInner className={`about_overview pt-180 pb-180`}>
            <ModalOverview show={show} data={pop?.section_data} handleClose={handleClose}/>

            <div className="icon-one box">
                <img src="/images/static/custom_3d_shapes_cube_1.svg" alt=""/>
            </div>

            <div className="icon-two boxr">
                <img src="/images/static/custom_3d_shapes_white_1.svg" alt=""/>
            </div>

            <div className="icon-three boxr">
                <img src="/images/static/custom_3d_shapes_white_3.svg" alt=""/>
            </div>

            <div className="noise"></div>

            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        <Title margin={'0 0 70px'} text={title ? title : ''}/>
                    </Col>
                    <Col data-lag={'0.2'} md={4}>
                        <h4 className={'split-up'}>{
                            subtitle &&
                            reactHtmlParser(subtitle)
                        }
                        </h4>
                    </Col>
                    <Col md={{span: 6, offset: 2}}>
                        {/*{*/}
                        {/*    description &&*/}
                        {/*    reactHtmlParser(description)*/}
                        {/*}*/}

                        {
                            description && (
                                <>
                                    {reactHtmlParser(description.slice(0, 700)+'...')}
                                </>
                            )
                        }

                        <div className="button" onClick={() => handleShow()}>
                            <Button src={'#'} margin={'40px 0 0'}
                                    color={'#F1EEE9'} icon_border={'#F1EEE9'} text={'LEARN MORE'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      background: #2F374B;
      background-image: url('/images/static/background-noise.png'), url('/images/static/bustling-cityscape-with-towering-buildings-busy-streets.png');
      background-repeat: repeat, no-repeat;
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;

    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }


  .icon-one {
    position: absolute;
    z-index: 1;
    left: -60px;
    top: 30px;
    filter: blur(5px);
  }


  .icon-two {
    position: absolute;
    z-index: 1;
    left: 60px;
    top: 60%;
    filter: blur(5px);
  }


  .icon-three {
    position: absolute;
    z-index: 1;
    right: -120px;
    bottom: 0;
    filter: blur(5px);
  }


  h4 {
    color: #C0845E;
  }

  h2, p {
    color: #F1EEE9;
  }


  @media (max-width: 767px) {
    h4 {
      margin-bottom: 40px;
    }

    .icon-one {
      left: -40px;

      img {
        height: 98px;
      }
    }

    .icon-two {
      top: unset;
      bottom: -10px;
      left: -60px;

      img {
        height: 120px;
      }
    }

    .icon-three {
      right: -50px;
      bottom: unset;
      top: 120px;

      img {
        height: 120px;
      }
    }
  }


`;


export default React.memo(OverviewInner);
