import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallaxSmall} from "./ImageParallaxSmall";

const MissionVision = (data) => {

    return (<StyledComponent  className={'mission-vision pt-200 pb-200'}>
        <Container>
            <Row className={'mission-vision-wrapper-row'}>
                <Col md={12} className={'mission-vision-col'}>
                    <div className="mission-vision__banner box reveal">
                        {/*<Img src={main_data?.images?.list?.[0].full_path ? main_data?.images?.list?.[0].full_path : '' }/>*/}
                        <ImageParallaxSmall visible src={data?.data?.images?.list?.find(f => f?.short_title === 'top')?.full_path}/>
                    </div>

                    <div className="box-up ">
                        <div  className="img-parallax-big ">
                            {/*<img src={main_data?.images?.list?.[1].full_path ? main_data?.images?.list?.[1].full_path  : 'images/static/blurbg.svg'} className={'left'} alt=""/>*/}
                            <img  src={data?.data?.images?.list?.find(f => f?.short_title === 'right')?.full_path} className={'left'} alt=""/>

                        </div>
                    </div>


                </Col>

                <Col className={'mission-vision__content flex-wrap p-0'} md={{span: 8, offset: 0}}>
                    <Col md={6} className={'pr-5 mission-vision-single'}>
                        <h3 className={'split-up'}>{'MISSION'}</h3>
                        <p className="split-up">
                            { data?.data?.section_data?.short_desc && reactHtmlParser(data?.data?.section_data?.short_desc)}
                        </p>
                    </Col>
                    <Col md={6} className={'pr-5 mission-vision-single'}>
                        <h3 className={'split-up'}>{'VISION'}</h3>
                        <p className="split-up">
                            {data?.data?.section_data?.description && reactHtmlParser(data?.data?.section_data?.description)}
                        </p>

                    </Col>
                </Col>
            </Row>
        </Container>
    </StyledComponent>);
};

const StyledComponent = styled.section`
  background-color: #F1EEE9;
  position: relative;
  overflow: hidden;

  .mission-vision-wrapper-row {
    .col-md-12.mission-vision-col {
      position: relative;

      img.left {
        position: absolute;
        object-fit: contain;
        right: 0;
        bottom: 0;
        transform: translateY(50%);
      }
    }

    .mission-vision__banner {
      padding-top: calc(604 / 1080 * 100%);
      position: relative;
      @media(min-width: 1024px){
        width: calc(100% - 16.666667%);
      }
    }


    .mission-vision__content {
      padding-left: 50px;
      margin-top: 70px;
      display: flex;

      h3 {
        margin-bottom: 40px;
      }


      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        color: #7E7576;

      }

    }
  }


  //responsive 
  @media (max-width: 1150px) {
    .mission-vision-wrapper-row {
      .col-md-12.mission-vision-col {
        img.left {
          max-width: 35%;
        }
      }
    }

  }
  @media (max-width: 992px) and (min-width: 768px) {
    .mission-vision-wrapper-row {
      .mission-vision__content {
        max-width: 100% !important;
        flex: 0 0 100% !important;

        .mission-vision-single {
          max-width: calc(100% - 35%) !important;
          flex: 0 0 calc(100% - 35%) !important;
          padding: 0;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
            max-width: 100% !important;
            flex: 0 0 100% !important;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .mission-vision-wrapper-row {
      .col-md-12.mission-vision-col {
        padding: 0;
        margin: 0;

        img.left {
          max-width: 35%;
        }
      }

    }

  }
  @media (max-width: 767px) {
    .mission-vision-wrapper-row {
      .col-md-12.mission-vision-col {
        padding: 0 15px;
        margin: 0;

        .img-parallax-big {
          text-align: right;

          img.left {
            position: relative;
            bottom: unset;
            right: 15px;
            transform: translateY(-25%);
            min-height: 170px;
            max-width: 50%;
          }
        }
      }

      .mission-vision__content {
        margin-top: 225px;

        .mission-vision-single {
          padding-right: 15px !important;

          &:last-child {
            margin-top: 40px;
          }
        }
      }
    }

  }




`;

export default React.memo(MissionVision);
