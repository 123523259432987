import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/concern";
import {ApiParam} from "../../api/network/apiParams";
import ConcernBox from "../../components/ConcernBox";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {SplitText} from "gsap/SplitText";
gsap.registerPlugin(SplitText, CSSPlugin);

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'concerns',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }

        dispath(fetchPosts([api_url, param]))


    }, [dispath])

    let getPost = useSelector(state => state.concernReducer)
    let page_data = getPost?.posts?.data?.page_data
    const concerns = getPost?.posts?.concerns?.list;
    const page = getPost?.posts?.data;


    // for close menu
    let tl2 = new TimelineLite();
    useEffect(() => {
        document?.querySelector('.main-menu')?.classList.remove('menu-is-open')
        document.body.classList.remove('stop-scroll')
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })



        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }

    }, [getPost,dispath])






    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Formonic Design & Construction Ltd</title>

                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }

            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={page?.page_data?.title}
                    subtext={page?.page_data?.short_desc}
                    img={page?.page_images?.list[0]?.full_path}/>
                <section className="concern-wrapper pt-120 ">
                    <Container>
                        <Row>
                            {
                                concerns && concerns?.length>0 &&
                                concerns?.map((e, i)=>{
                                    return(
                                        <Col md={4} className={'concern-list-single-col'} key={i}>
                                            <ConcernBox link={`/concerns/${e?.page_data?.slug}`} title={reactHtmlParser(e?.page_data?.title)}
                                                        des={e?.page_data?.short_desc}
                                                        img={e?.images?.list?.find(f => f.is_thumb === 'on')?.full_path}
                                                        logo={e?.images?.list?.find(f => f.logo === 'on')?.full_path}
                                            />
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .concern-wrapper {
    padding-bottom: 60px;
    background: #F1EEE9;

    .concern-list-single-col {
      margin-bottom: 60px;
    }
  }


  @media (max-width: 767px) {
    .concern-wrapper {
      background: #F1EEE9;
      padding-bottom: 80px;

      .concern-list-single-col {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
