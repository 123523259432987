import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import CareerModal from "../CareerModal";
import reactHtmlParser from "react-html-parser";

const Job = ({data}) => {

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }


    return (
        <StyledOverviewInner className={`jobs pt-180 pb-180`}>
            <CareerModal show={show} data={popupId} handleClose={handleClose}/>

            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        <Title text={'VACANCIES'} margin={'0 0 80px'}/>
                    </Col>
                    <Col md={12}>
                        <div className="list">
                            {
                                data && data?.length>0 &&
                                data?.map((e, i)=>{
                                    return(
                                        <div className="single-item" key={i}>
                                            <h4 className={'split-up'}>{reactHtmlParser(e?.data?.title)}</h4>
                                            <div className="time">
                                                {
                                                    reactHtmlParser(e?.data?.overview)
                                                }
                                            </div>
                                            <div className="button" onClick={() => handleShow(e)}>
                                                <Button src={'#'} margin={'0'} text={'VIEW DETAILS'}/>
                                            </div>
                                        </div>
                                    );
                                })

                            }
                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;
  background: #F1EEE9;

  .list {
    .single-item {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(198, 198, 198, 0.5);

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        width: 50%;
        color: #26201E;
        letter-spacing: 0;
      }

      .time {
        width: 30%;

        p {
          margin-bottom: 10px;
          color: #C0845E;
          

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .button {
        //width: 30%;
      }
    }

    @media (max-width: 992px) {
      .single-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 40px;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        h4 {
          margin-bottom: 20px;
          width: 100%;
        }

        .time {
          width: 100%;

          p {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .button {
          margin-top: 20px;
          //width: 30%;
        }
      }

    }
  }



`;


export default React.memo(Job);
