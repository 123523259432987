import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className="at-a-glance pt-180 pb-180">
           <Container>
               <Row>
                   <Col md={5} className={'at-a-glance__right'}>
                       <div className="at-a-glance__right__image reveal">
                           <Img src={data?.images[0]?.full_path}/>
                       </div>
                   </Col>
                   <Col md={7} className={'at-a-glance__left'}>
                       <Title text={data?.data?.title} margin={'0 0 80px'} />
                       {
                           reactHtmlParser(data?.data?.description)
                       }

                   </Col>


               </Row>
           </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #f1eee9;
  .at-a-glance {
    &__left {
      padding-left: 80px;
      h2 {
        color: #C0845E;
        margin-left: 50px;
      }
      
      ul{
        li{
          display: flex;
          align-items: flex-start;
          margin-bottom: 40px;
          &:last-child{
            margin: 0;
          }
          .icon{
            flex: 0 0 50px;
          }
          .content{
            display: flex;
            flex: 0 0 calc(100% - 50px);
            .item{
              flex: 0 0 calc(70% - 50px);
              p{
                color: #7E7576;
                text-transform: uppercase;
              }
            }
            .value{
              //flex: 0 0 calc(50% - 100px);
              p{
                color: #26201E;
                text-transform: uppercase;
              }
            }
          }
        
        }
      }

    }

    &__right {
      padding: 0;
      &__image {
        position: relative;
        padding-top: calc(620 / 470 * 100%);
        min-height: 100%;
      }
    }
  }
  
  
  @media(max-width: 767px){
    .at-a-glance {
      &__left {
        margin-top: 30px;
        padding-left: 15px;
        h2 {
          margin-left: 0;
        }

        ul{
          li{
            &:last-child{
              margin: 0;
            }
            .content{
              flex-direction: column;
            }
          }
        }

      }

      &__right {
        padding: 0;
        &__image {
          position: relative;
          padding-top: calc(620 / 470 * 100%);
          min-height: 100%;
        }
      }
    }
  }
`;

export default MyComponent;
