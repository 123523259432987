import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import {ImageParallax} from "../ImageParallax";


const InnerBanner = ({img, subtext, title, uptitle,project,notFull, logo}) => {
    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner '>
            <ImageParallax banner={true} src={img}/>
            <Container>
                <Row>
                    <Col md={12}>
                        <img className={'logo'} src={logo ? logo : '/images/dynamic/logo.svg'} alt=""/>
                        <p className={'up-title'}>{uptitle ? uptitle : reactHtmlParser(uptitle)}</p>
                        <h1>{title ? reactHtmlParser(title) : ''}</h1>
                        <p>{subtext ? reactHtmlParser(subtext) : ''}</p>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : 'calc(768 / 1366 * 100%)'} ;
  position: relative;
  background-color: #DDD;
  overflow: hidden;
  height:${props => props.notFull ? '75vh' : '100vh'};
  .logo{
    height: 35px;
    //margin-bottom: 40px;
  }
  &:after {
    //content: '';
    position: absolute;
    background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    h1 {
      margin-bottom: ${props => props.project ? '30px' : '15px'} ;
    }

    h1, p {
      color: #F1EEE9;
      text-transform: uppercase;
    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }


  @media (max-width: 376px) {
    padding-top: calc(812 / 375 * 100%);
    h1{
      font-size: 36px;
      line-height: 48px;
    }
  }




`;

export default InnerBanner;
