import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const SplitUp = () => {
    const location = useLocation();
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);


    useEffect(() => {

        document.fonts.ready.then(() => {
            gsap.utils.toArray('.split-up').forEach((item, i) => {
                const parentSplit = new SplitText(item, {
                    linesClass: "split-parent",
                    type: "lines",
                })
                const childSplit = new SplitText(item, {
                    type: "lines",
                    linesClass: "split-child"
                })

                gsap.from(parentSplit.lines, {
                    duration: 1,
                    // delay: .02,
                    yPercent: 150,
                    ease: 'power4.out',
                    stagger: .08,
                    scrollTrigger: {
                        trigger: item,
                        toggleActions: "restart none none reset",
                        // start: "top 60%",
                        // markers: true
                    }
                })

            });
        })




    }, [location.pathname])
}

/*
how to use:
- add 'split-up' class on the tag like(h1,h2,h3,p ... etc)
*/