import React, {useState} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, StyledMap} from '@react-google-maps/api';
import Button from "./Button";
import styled from 'styled-components';


const GoogleMapsMarkers = ({data}) => {
    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const defaultCenter = {
        lat: 23.801927, // Replace with marker latitude
        lng: 90.4029675, // Replace with marker longitude
    };

    const [selectedMarker, setSelectedMarker] = useState(null);


    const markers = data && Array.isArray(data) ? data.map((item, index) => {
        return {
            id: index,
            position: { lat: parseFloat(item?.product_data?.latitude), lng: parseFloat(item?.product_data?.longitude)},
            icon: '/images/static/icon.svg',
            content: item?.product_data?.title,
            type: item?.product_data?.type,
            type2: item?.product_data?.location,
            katha: item?.product_data?.katha,
            storied: item?.product_data?.storied,
            facing: item?.product_data?.facing,
            image: item?.images?.list,
            slug: item?.product_data?.slug,
        };
    }) : [];

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };


    const customMapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]; // Paste your copied JSON style here

    const mapOptions = {
        styles: customMapStyle,
    };


    return (
        // <LoadScript googleMapsApiKey="AIzaSyB3nEqKsliw_EQSpaxAq6R8hOUwpGyxJ9s">
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={defaultCenter}
                options={mapOptions}

            >


                {markers.map((marker) => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={marker.icon}
                        onClick={() => handleMarkerClick(marker)}
                    >
                    </Marker>


                ))}

                {selectedMarker && (
                    <InfoWindow
                        position={selectedMarker.position}
                        onCloseClick={handleInfoWindowClose}
                    >
                        <div>
                            <ul>
                                <li>{selectedMarker.type}</li>
                                <li>{selectedMarker.type2}</li>
                            </ul>
                            <p>{selectedMarker.content}</p>
                        </div>
                    </InfoWindow>
                )}

            </GoogleMap>
        // </LoadScript>

    );
};


export default React.memo(GoogleMapsMarkers)