import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Accordions from "./service/Accordions";


const AccordionImage = ({offset, revers, padding, data}) => {

    return (
        <StyledAccordionImage offset={offset} className={`accordion-image ${padding ? padding : 'pt-180 pb-180'}`}>
            <Container fluid>
                {
                    revers ?

                        <Row className={'reverse'}>
                            <Col md={{span: 5}} className={'padding-left'}>
                                <h3>
                                    {data?.section_data?.title}
                                </h3>
                                <Accordions data={data?.posts}/>
                            </Col>
                            <Col md={{span: 6, offset: 1}} className={'p-0'}>
                                <div className="img-wrapper reveal">
                                    <Img visible src={data?.images?.list[0]?.full_path}  alt=""/>
                                </div>
                            </Col>

                        </Row>

                        :
                        <Row >
                            <Col md={5} className={'p-0'}>
                                <div className="img-wrapper reveal">
                                    <Img visible src={data?.images?.list[0]?.full_path} alt=""/>
                                </div>
                            </Col>
                            <Col md={{span: 6, offset: 1}} className={'padding-right'}>
                                <h3>
                                    {data?.section_data?.title}
                                </h3>
                                <Accordions data={data?.posts}/>
                            </Col>
                        </Row>
                }


            </Container>
        </StyledAccordionImage>
    )
};

const StyledAccordionImage = styled.div`
  background: #F1EEE9;

  .img-wrapper {
    padding-top: calc(600 / 598 * 100%);
    position: relative;
  }
  .padding-right {
    padding-right: ${props => props.offset + 0}px;
  }
  .padding-left {
    padding-left: ${props => props.offset + 0}px;
  }
  
  @media(max-width: 767px){
    .padding-right {
      padding-right: 15px !important;
      margin-top: 40px;
    }
    .padding-left {
      margin-top: 40px;
      padding-left: 15px !important;
    }
    .reverse{
      flex-direction: column-reverse;
    }
  }
`;


export default React.memo(AccordionImage);
