import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Accordions from "./service/Accordions";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";


const ConsultencyBlock = ({offset, revers, padding, data}) => {


    return (
        <StyledBuilHome offset={offset} className={`BuilHome ${padding ? padding : ''}`}>
            <div className="main-image-sec">
                <Img src={data?.images?.list[0]?.full_path}/>
                <div className="content">
                    <Title margin={'0 0 30px'} color={'#F1EEE9'} text={reactHtmlParser(data?.section_data?.title)}/>
                    <p className={'split-up'}>
                        {
                            reactHtmlParser(data?.section_data?.short_desc)
                        }
                    </p>
                </div>
            </div>

        </StyledBuilHome>
    )
};

const StyledBuilHome = styled.div`
  position: relative;
  .main-image-sec {
    padding-top: calc(580 / 1366 * 100%);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 3;
      background: linear-gradient(to bottom, rgba(38, 32, 30, 0), #26201E);
    }  
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 3;
      opacity: 0.3;
      background: #000000;
    }
    
    .content{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 5;
      text-align: center;
      p{
        color:#F1EEE9;
      }
    }
  
  }

  @media(max-width: 767px){
    .main-image-sec .content{
      width: 100%;
    }
    .main-image-sec {
      padding-top: calc(190.4597%);
      position: relative;
    }
  }
`;


export default React.memo(ConsultencyBlock);
