import React from 'react';
import styled from 'styled-components';
import {hover, medium, text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Social = () => {


    return (

        <StyledSocial>
            <ul className={'social'}>
                <li><a href={''}
                       target={'_blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                         viewBox="0 0 30 30">


                        <g id="Group_19435" data-name="Group 19435"
                           transform="translate(15536 -9208)">
                            <circle id="Ellipse_602" data-name="Ellipse 602" cx="15" cy="15"
                                    r="14"
                                    transform="translate(-15536 9208)" fill="#3C3C3B"
                                    stroke="#3C3C3B"
                                    stroke-width="1"/>
                            <circle id="Ellipse_603" data-name="Ellipse 603" cx="15" cy="50"
                                    r="15"
                                    transform="translate(-15536 9208)" fill="#978c21"/>
                            <path id="Path_2115" data-name="Path 2115"
                                  d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                  transform="translate(-16723.787 9119.901)"
                                  fill="#ffffff"/>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={''}
                       target={'_blank'}>


                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                         viewBox="0 0 30 30">


                        <g id="Group_19435" data-name="Group 19435"
                           transform="translate(15536 -9208)">
                            <circle id="Ellipse_602" data-name="Ellipse 602" cx="15" cy="15"
                                    r="14"
                                    transform="translate(-15536 9208)" fill="#3C3C3B"
                                    stroke="#3C3C3B"
                                    stroke-width="1"/>
                            <circle id="Ellipse_603" data-name="Ellipse 603" cx="15" cy="50"
                                    r="15"
                                    transform="translate(-15536 9208)" fill="#978c21"/>


                        </g>
                        <g id="Group_1419" data-name="Group 1419"
                           transform="translate(8 8)">

                            <path id="Path_2109" data-name="Path 2109"
                                  d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                  transform="translate(-1084.635 -103.346)" fill="#ffffff"/>
                            <path id="Path_2110" data-name="Path 2110"
                                  d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                  transform="translate(-1075.301 -104.911)" fill="#ffffff"/>
                            <path id="Path_2111" data-name="Path 2111"
                                  d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                  transform="translate(-1069.932 -99.628)" fill="#ffffff"/>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={''}
                       target={'_blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                         viewBox="0 0 30 30">
                        <g id="Group_19435" data-name="Group 19435"
                           transform="translate(15536 -9208)">
                            <circle id="Ellipse_602" data-name="Ellipse 602" cx="15" cy="15"
                                    r="14"
                                    transform="translate(-15536 9208)" fill="#3C3C3B"
                                    stroke="#3C3C3B"
                                    stroke-width="1"/>
                            <circle id="Ellipse_603" data-name="Ellipse 603" cx="15" cy="50"
                                    r="15"
                                    transform="translate(-15536 9208)" fill="#978c21"/>


                        </g>
                        <g id="linkedin-logotype" transform="translate(8 8)">
                            <path id="Path_8798" data-name="Path 8798"
                                  d="M1.456,1.211a1.121,1.121,0,0,1,.875.351,1.355,1.355,0,0,1,.34.881,1.2,1.2,0,0,1-.358.881,1.329,1.329,0,0,1-.98.358H1.317a1.3,1.3,0,0,1-.962-.358A1.208,1.208,0,0,1,0,2.442a1.128,1.128,0,0,1,.391-.884A1.552,1.552,0,0,1,1.456,1.211ZM.156,4.659H2.71v7.173H.156ZM11.943,7.72v4.113H9.39V8a2.076,2.076,0,0,0-.3-1.177,1.114,1.114,0,0,0-1-.443,1.279,1.279,0,0,0-.858.273,1.632,1.632,0,0,0-.463.6,1.044,1.044,0,0,0-.06.265,3.071,3.071,0,0,0-.014.31v4.008H4.126q.016-1.821.017-3.337V6.062c0-.353,0-.654-.01-.9s-.007-.415-.007-.5H6.7V5.675L6.681,5.7H6.7V5.675A2.953,2.953,0,0,1,7,5.283a2.346,2.346,0,0,1,.46-.382,2.622,2.622,0,0,1,.656-.291A3.1,3.1,0,0,1,9,4.5a3.494,3.494,0,0,1,1.166.19,2.392,2.392,0,0,1,.935.6,2.848,2.848,0,0,1,.617,1.007A4.105,4.105,0,0,1,11.943,7.72Z"
                                  transform="translate(0)" fill="#ffffff"/>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={''}
                       target={'_blank'}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                         viewBox="0 0 30 30">

                        <g id="Group_19435" data-name="Group 19435"
                           transform="translate(15536 -9208)">
                            <circle id="Ellipse_602" data-name="Ellipse 602" cx="15" cy="15"
                                    r="14"
                                    transform="translate(-15536 9208)" fill="#3C3C3B"
                                    stroke="#3C3C3B"
                                    stroke-width="1"/>
                            <circle id="Ellipse_603" data-name="Ellipse 603" cx="15" cy="50"
                                    r="15"
                                    transform="translate(-15536 9208)" fill="#978c21"/>


                        </g>
                        <path id="Path_2114" data-name="Path 2114"
                              d="M1146.9,113.13a2.813,2.813,0,0,0-2.813-2.813h-7.195a2.813,2.813,0,0,0-2.813,2.813v3.348a2.813,2.813,0,0,0,2.813,2.813h7.195a2.813,2.813,0,0,0,2.813-2.813Zm-4.231,1.925-3.226,1.6c-.126.068-.556-.023-.556-.167v-3.276c0-.146.433-.237.56-.165l3.089,1.68C1142.661,114.8,1142.8,114.985,1142.666,115.056Z"
                              transform="translate(-1125.074 -99.317)" fill="#ffffff"/>
                    </svg>

                </a></li>
            </ul>
        </StyledSocial>

    )
};


const StyledSocial = styled.div`
  margin-bottom: 40px;

  .social {
    display: flex;
    flex-direction: row;

    svg {
      overflow: hidden;
      border-radius: 50%;

      #Ellipse_603, path {
        transition: 1s all cubic-bezier(.25, .74, .22, .99);
      }
    }

    li {
      margin-right: 20px;

      &:first-child {
        margin-left: 0;
      }

      a {
        &:hover {
          svg {
            #Ellipse_603 {
              cy: 15px;
            }

            path {
              fill: white;
            }


          }
        }
      }
    }
  }

`;

export default React.memo(Social);














