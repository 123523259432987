import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    hoverColor,
                    target,
                    borderColor,
                    icon_border, hide_box, no_hover
                }) => {


    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   no_hover={no_hover}
        >
            {src ? (

                <Link to={src || '/'}>
                    {
                        no_hover ?

                            <svg className={no_hover ? 'no_hover' : ''} xmlns="http://www.w3.org/2000/svg" width="16.707" height="11.414"
                                 viewBox="0 0 16.707 11.414">
                                <g id="Arrow" transform="translate(-1307.5 -242.793)">
                                    <line id="Line_12400" data-name="Line 12400" x2="5" y2="5"
                                          transform="translate(1318.5 243.5)" fill="none" stroke={`${icon_border ? icon_border : '#26201e'}`}
                                          stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12401" data-name="Line 12401" y1="5" x2="5"
                                          transform="translate(1318.5 248.5)" fill="none" stroke={`${icon_border ? icon_border : '#26201e'}`}
                                          stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12402" data-name="Line 12402" x2="15"
                                          transform="translate(1308 248.5)" fill="none" stroke={`${icon_border ? icon_border : '#26201e'}`}
                                          stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </svg>


                            :

                            <svg id="Button_-_Nav_-_R" data-name="Button - Nav - R" xmlns="http://www.w3.org/2000/svg"
                                 width="45" height="45" viewBox="0 0 45 45">
                                <g id="Base" fill="none" stroke={`${icon_border ? icon_border : '#26201e'}`}
                                   stroke-width="1" opacity={`${hide_box ? '0' : '0.3'}`}>
                                    <rect width="45" height="45" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                                </g>
                                <g id="Arrow" transform="translate(-1293.25 -226)">
                                    <line id="Line_12400" data-name="Line 12400" x2="5" y2="5"
                                          transform="translate(1318.5 243.5)" fill="none"
                                          stroke={`${icon_border ? icon_border : '#26201e'}`} stroke-linecap="round"
                                          stroke-width="1"/>
                                    <line id="Line_12401" data-name="Line 12401" y1="5" x2="5"
                                          transform="translate(1318.5 248.5)" fill="none"
                                          stroke={`${icon_border ? icon_border : '#26201e'}`} stroke-linecap="round"
                                          stroke-width="1"/>
                                    <line id="Line_12402" data-name="Line 12402" x2="15"
                                          transform="translate(1308 248.5)" fill="none"
                                          stroke={`${icon_border ? icon_border : '#26201e'}`} stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                                <g id="Hover" fill="none" stroke={`${icon_border ? icon_border : '#26201e'}`}
                                   stroke-width="1" opacity={`${hide_box ? '0' : '0'}`} stroke-dasharray="0 200">
                                    <rect width="45" height="45" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                                </g>
                            </svg>
                    }


                    <span> {ReactHtmlParser(text) || 'explore'}  </span>
                </Link>
            ) : (
                <a target={target}>
                    <span>{ReactHtmlParser(text) || 'explore'}</span>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: max-content;
    //height: 100%;
    cursor: pointer;

    a {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '15'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      line-height: ${props => props.lineHeight || '22'}px;
        //border: 1px solid ${props => props.borderColor || "#222222"};
      background-color: ${props => props.background || `transparent`};
      position: relative;
        // border-radius: ${props => props.borderRadius || '17px'} !important;
      overflow: hidden;
      z-index: 0;
      //box-sizing: border-box;
      cursor: pointer;
      transition: all .3s ease;
      letter-spacing: 1.35px;

      span {
        transition: all .3s ease;
        color: ${props => props.color || '#26201E'};
        margin-left: ${props => props.no_hover ? '25px' : '25px'};

        svg {
          path {
            transition: all .3s ease;
          }
        }
      }

      svg {
        transition: all 0.3s ease;

        g {
          transition: all 0.3s ease;
        }
        #Line_12402 {
          transition: transform 0.3s ease;  /* Add a smooth transition effect */
        }
      }


      &:hover {
        border-color: ${hover};

        &:before {
          height: 100%;
        }
        .no_hover{
          transform: translateX(10px);
        }
        svg {
          #Hover {
            stroke-dasharray: ${props => props.no_hover ? '0' : '180px 200px'};
            opacity: ${props => props.no_hover ? '0' : '1'};
          }

          #Line_12402 {
          }
        }
      }

      &:focus {
        color: #222222;
      }
    }
  }

  @media (max-width: 550px) {
    width: 100% !important;
    
    a{
      justify-content: flex-start !important;
    }
  }

`;


export default React.memo(Button);
