import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import NavigationIcon from "./NavigationIcon";
import StoryBox from "./StoryBox";

const SuccessSlider = ({data, id, no_slider, bg, padding}) => {


    let [offset, setOffset] = useState(90)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.thisis').offsetLeft + 0)
        }
    }, [])
    const [current, setCurrent] = useState('1')
    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    // let title = data?.section_data?.title;
    let item_list = data;



    return (
        <StyledComponent bg={bg} container={offset} id={`${id ? id : "Sustainability"}`}
                         className={`sustain  ${padding ? padding : 'pt-180 pb-180'} ${no_slider ? 'slider_no' : ''}`}>
            <Container className={'thisis'}>
                <Row>
                    <Col md={12}>
                        <div className={'sustain__top d-flex'}>
                            <h2 className={''}>Our Success Story</h2>
                            <NavigationIcon color={'#26201E'} next_id={'story-next'} prev_id={'story-prev'}/>

                        </div>

                    </Col>
                </Row>
            </Container>
            <Container className={``}>
                {
                    item_list?.length > 0 &&
                    <Swiper
                        Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        allowTouchMove={true}
                        autoHeight={true}
                        slidesPerView={3}
                        pagination={{
                            type: "fraction",
                        }}
                        navigation={{
                            prevEl: '#story-prev',
                            nextEl: '#story-next',
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = '#story-prev';
                            swiper.params.navigation.nextEl = '#story-next';
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,

                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,

                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        loop={true}
                        onSlideChange={(s) => handleSliderCurrent()}
                    >

                        {
                            item_list?.length > 0 &&
                            item_list?.map((e, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        {
                                            e?.page_data?.short_desc ?
                                                <StoryBox video data={e}/> :
                                                <StoryBox data={e}/>
                                        }

                                    </SwiperSlide>
                                )
                            })
                        }


                    </Swiper>
                }

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: ${props => props.bg ? props.bg : 'inherit'};


  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }


  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .sustain__top {
    padding-bottom: 10px;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    justify-content: space-between;
    margin-bottom: 50px;

    p {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .title {
      h2 {
        font-size: 40px;
      }
    }
    
    .sustain__top{
      flex-direction: column;
      h2{
        margin-bottom: 40px;
      }
    }
    
    .wrapper{
      padding-right: 0;
    }
  }




`;

export default React.memo(SuccessSlider);
