import React from 'react';
import styled from "styled-components";
import {Accordion} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import {body_font, hover} from "../styles/globalStyleVars"
import GoogleMapsComponent from "./GoogleMapsComponent";

const ForMap = ({data, lat, long, title}) => {
    return (
        <StyledMap className='map-for-all'>
            <GoogleMapsComponent data={data?.section_data?.subtitle} title={title} long={long} lat={lat}/>
        </StyledMap>
    );
};


const StyledMap = styled.section`
    height: 100vh;
`;


export default React.memo(ForMap);