import React,{useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {hover, title} from "../styles/globalStyleVars";
import {BsWhatsapp} from 'react-icons/bs';
import {Link} from "react-router-dom";
import gsap from "gsap";

const MyComponent = ({footerTop, footerTopBg}) => {


    return (
        <>
            <Footer footerTopBg={footerTopBg} className={'footer'}>
                <div className="noise"></div>
                <Container>
                    <Row className={`footer__social footer-social rotate_svg ${!footerTop && 'pt-120'}`}>
                        <Col sm={{span: 4, offset: 4}} className={'d-flex justify-content-center'}>
                            <img src={'/images/static/logo.svg'} alt=""/>
                        </Col>
                    </Row>

                    <Row className={'footer__menu'}>
                        {
                            window.innerWidth > 767 &&
                            <Col>
                                <ul>
                                    <li><Link to="/"><a>home</a></Link></li>
                                    <li><Link to="/about"><a>about us</a></Link></li>
                                    <li><Link to="/project"><a>projects</a></Link></li>
                                    <li><Link to="/for-sale"><a>for sale</a></Link></li>
                                    <li><Link to="/services"><a>services</a></Link></li>
                                    <li><Link to="/consultancy"><a>consultancy</a></Link></li>
                                    <li><Link to="/concerns"><a>Concerns</a></Link></li>
                                    <li><Link to="/csr"><a>csr</a></Link></li>
                                    <li><Link to="/career"><a>career</a></Link></li>
                                    <li><Link to="/contact-us"><a>contact</a></Link></li>
                                </ul>
                            </Col>
                        }
                        {
                            window.innerWidth < 767 &&
                            <div className={'footer__big-number'}>
                                <Col className={'d-flex flex-wrap justify-content-center'}>
                                    <ul>
                                        <li><a href="tel:+8801785-888000">+8801785-888000</a></li>
                                        <li><a href="mailto:hanif07buet@gmail.com">hanif07buet@gmail.com</a></li>
                                    </ul>
                                    <p>Business Point, Level-5, Plot no-77&78, Road No-3&4 (Main Road), Block-I, Bashundhara-R/A, Dhaka-1229</p>

                                </Col>
                            </div>

                        }
                        <div className="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/p/Formonic-Design-Construction-Ltd-100040422192332/" target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30">
                                            <g id="Group_22574" data-name="Group 22574"
                                               transform="translate(13704 -5678)">
                                                <path id="Path_26" data-name="Path 26"
                                                      d="M1013.328,3919.447v-1.17a2.525,2.525,0,0,1,.025-.408.792.792,0,0,1,.111-.285.454.454,0,0,1,.28-.193,1.98,1.98,0,0,1,.516-.054h1.17v-2.341h-1.871a3.046,3.046,0,0,0-2.333.771,3.248,3.248,0,0,0-.709,2.271v1.41h-1.4v2.341h1.4v6.793h2.811v-6.793h1.872l.247-2.341Z"
                                                      transform="translate(-14701.282 1771.213)" fill="#7e7576"/>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/formonic-design-and-technology" target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30">
                                            <g id="Group_22573" data-name="Group 22573"
                                               transform="translate(13668 -5678)">

                                                <g id="Group_28" data-name="Group 28"
                                                   transform="translate(-13660.148 5685.5)">
                                                    <path id="LinkedIn"
                                                          d="M1101.732,3924.682v5.286h-3.064v-4.932c0-1.239-.443-2.084-1.553-2.084a1.676,1.676,0,0,0-1.572,1.121,2.1,2.1,0,0,0-.1.748v5.147h-3.066s.041-8.353,0-9.218h3.065v1.307c-.006.009-.014.02-.02.029h.02v-.029a3.043,3.043,0,0,1,2.762-1.523C1100.219,3920.533,1101.732,3921.851,1101.732,3924.682Zm-12.563-8.375a1.6,1.6,0,1,0-.041,3.186h.021a1.6,1.6,0,1,0,.02-3.186Zm-1.552,13.661h3.064v-9.218h-3.064Z"
                                                          transform="translate(-1087.435 -3916.306)" fill="#7e7576"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li>
                                    <a href="#" target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30">
                                            <g id="Group_22572" data-name="Group 22572"
                                               transform="translate(13632 -5678)">

                                                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
                                                      d="M6.628,5.468a3.4,3.4,0,1,0,3.4,3.4A3.395,3.395,0,0,0,6.628,5.468Zm0,5.61a2.21,2.21,0,1,1,2.21-2.21,2.214,2.214,0,0,1-2.21,2.21ZM10.96,5.329a.793.793,0,1,1-.793-.793A.791.791,0,0,1,10.96,5.329Zm2.252.8a3.924,3.924,0,0,0-1.071-2.779A3.95,3.95,0,0,0,9.362,2.284c-1.095-.062-4.376-.062-5.471,0A3.945,3.945,0,0,0,1.112,3.352,3.937,3.937,0,0,0,.041,6.131c-.062,1.095-.062,4.376,0,5.471a3.924,3.924,0,0,0,1.071,2.778,3.955,3.955,0,0,0,2.779,1.071c1.095.062,4.376.062,5.471,0a3.924,3.924,0,0,0,2.779-1.071A3.95,3.95,0,0,0,13.212,11.6c.062-1.095.062-4.373,0-5.468ZM11.8,12.777a2.238,2.238,0,0,1-1.261,1.261,14.615,14.615,0,0,1-3.909.266,14.729,14.729,0,0,1-3.909-.266,2.238,2.238,0,0,1-1.261-1.261,14.614,14.614,0,0,1-.266-3.909,14.728,14.728,0,0,1,.266-3.909A2.238,2.238,0,0,1,2.719,3.7a14.614,14.614,0,0,1,3.909-.266,14.728,14.728,0,0,1,3.909.266A2.238,2.238,0,0,1,11.8,4.959a14.614,14.614,0,0,1,.266,3.909A14.606,14.606,0,0,1,11.8,12.777Z"
                                                      transform="translate(-13623.627 5684.131)" fill="#7e7576"/>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li>
                                    <a href="#" target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30">
                                            <g id="Group_22571" data-name="Group 22571"
                                               transform="translate(13596 -5678)">

                                                <g id="Group_31" data-name="Group 31"
                                                   transform="translate(-13588.5 5688.5)">
                                                    <path id="Path_29" data-name="Path 29"
                                                          d="M1180.424,3919.7c-.523-.622-1.49-.875-3.336-.875h-6.7c-1.888,0-2.87.271-3.392.933-.509.646-.509,1.6-.509,2.915v2.511c0,2.552.6,3.847,3.9,3.847h6.7c1.6,0,2.488-.224,3.061-.773.589-.563.84-1.482.84-3.074v-2.511C1180.989,3921.283,1180.95,3920.325,1180.424,3919.7Zm-4.627,4.574-3.043,1.591a.468.468,0,0,1-.684-.415v-3.17a.469.469,0,0,1,.684-.416l3.042,1.58a.468.468,0,0,1,0,.83Z"
                                                          transform="translate(-1166.487 -3918.824)" fill="#7e7576"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li>
                                    <a href="" target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30">
                                            <g id="Group_22570" data-name="Group 22570"
                                               transform="translate(13560 -5678)">

                                                <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter"
                                                      d="M12.948,6.3c.009.128.009.256.009.385A8.358,8.358,0,0,1,4.542,15.1,8.358,8.358,0,0,1,0,13.774a6.119,6.119,0,0,0,.714.037,5.924,5.924,0,0,0,3.672-1.264A2.963,2.963,0,0,1,1.621,10.5a3.73,3.73,0,0,0,.559.046,3.128,3.128,0,0,0,.778-.1,2.958,2.958,0,0,1-2.372-2.9V7.5a2.979,2.979,0,0,0,1.337.375,2.962,2.962,0,0,1-.916-3.956,8.407,8.407,0,0,0,6.1,3.1,3.339,3.339,0,0,1-.073-.678,2.961,2.961,0,0,1,5.119-2.024A5.823,5.823,0,0,0,14.029,3.6a2.95,2.95,0,0,1-1.3,1.63,5.929,5.929,0,0,0,1.7-.458A6.358,6.358,0,0,1,12.948,6.3Z"
                                                      transform="translate(-13552.5 5683.619)" fill="#7e7576"/>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Row>

                    {
                        window.innerWidth > 767 &&
                        <Row className={'footer__big-number'}>
                            <Col className={'d-flex flex-wrap justify-content-center'}>
                                <ul>
                                    <li><a href="tel:+8801785-888000">+8801785-888000</a></li>
                                    <li><a href="mailto:hanif07buet@gmail.com">hanif07buet@gmail.com</a></li>
                                </ul>
                                <p>Business Point, Level-5, Plot no-77&78, Road No-3&4 (Main Road), Block-I, Bashundhara-R/A, Dhaka-1229</p>

                            </Col>
                        </Row>

                    }

                    {
                        window.innerWidth < 767 &&
                        <Row className={'footer__menu mobile_menu'}>
                            <Col>
                                <ul>
                                    <li><Link to="/"><a>home</a></Link></li>
                                    <li><Link to="/about"><a>about us</a></Link></li>
                                    <li><Link to="/project"><a>projects</a></Link></li>
                                    <li><Link to="/for-sale"><a>for sale</a></Link></li>
                                    <li><Link to="/services"><a>services</a></Link></li>
                                    <li><Link to="/consultancy"><a>consultancy</a></Link></li>
                                    <li><Link to="/concerns"><a>Concerns</a></Link></li>
                                    <li><Link to="/csr"><a>csr</a></Link></li>
                                    <li><Link to="/career"><a>career</a></Link></li>
                                    <li><Link to="/contact-us"><a>contact</a></Link></li>
                                </ul>
                            </Col>
                        </Row>
                    }


                    <div className={'footer__copyright d-flex justify-content-between'}>
                        <p>© {new Date().getFullYear()} Formonic Design & Construction Ltd. All Rights Reserved</p>
                        <p>Designed & Developed by <a href={'https://dcastalia.com/'} target={'_blank'}>Dcastalia
                            Limited</a></p>


                    </div>

                </Container>
            </Footer>
        </>
    );
};

const Footer = styled.section`
  position: relative;
  overflow: hidden;
  @keyframes Noise_grain {
    0%,to {
      -webkit-transform: translate(0);
      transform: translate(0)
    }

    10% {
      -webkit-transform: translate(-5%,-10%);
      transform: translate(-5%,-10%)
    }

    20% {
      -webkit-transform: translate(-15%,5%);
      transform: translate(-15%,5%)
    }

    30% {
      -webkit-transform: translate(7%,-25%);
      transform: translate(7%,-25%)
    }

    40% {
      -webkit-transform: translate(-5%,25%);
      transform: translate(-5%,25%)
    }

    50% {
      -webkit-transform: translate(-15%,10%);
      transform: translate(-15%,10%)
    }

    60% {
      -webkit-transform: translate(15%);
      transform: translate(15%)
    }

    70% {
      -webkit-transform: translateY(15%);
      transform: translateY(15%)
    }

    80% {
      -webkit-transform: translate(3%,25%);
      transform: translate(3%,25%)
    }

    90% {
      -webkit-transform: translate(-10%,10%);
      transform: translate(-10%,10%)
    }
  }
  padding: 80px 0 40px;

  .noise {
    position: absolute;
    background: #26201E;
    background-image: url('/images/static/background-noise.png');
    //background-image: url('https://grandbourget.com/wp-content/themes/grand-byrze/assets/images/noise.png');
    //background-repeat: repeat;
    background-blend-mode: multiply;
    animation: Noise_grain 7s steps(10) infinite;
    width: 300%;
    height: 300%;
    position: absolute;
    left: -50%;
    top: -100%;
    right: 0;
    bottom: 0;

    &:after {
      content: '';
  
    }

  }

  img.top-icon {
    position: absolute;
    top: -94px;
    left: 0;
  }

  .footer-social {
    ul {
      display: flex;
      width: 100%;


      li {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;

          svg {
            position: relative;
            z-index: 2;

            g {
            }
          }

          &:hover {
            path {
              fill: #FFF;
            }
          }
        }

        .hover:after {
          background-color: #191818;
        }

        &:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }
  }

  .footer__menu {
    ul {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      flex-wrap: wrap;

      li {
        a {
          text-transform: uppercase;
          color: #F1EEE9;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 1px;
        }

        &:not(:nth-last-of-type(1)) {
          margin-right: 30px;
        }
      }
    }

    .footer-social {
      margin-top: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 10px;

      ul {
        li {
          width: auto !important;
          min-width: unset;
          max-width: unset;
          margin-right: 20px !important;
        }
      }


    }

  }

  .footer__social {
    img {
      height: 120px;
    }
  }

  .footer__big-number {
    .justify-content-center {
      justify-content: center !important;
      flex-direction: column;
      align-items: center;

      ul {
        display: flex;
        margin-bottom: 10px;

        li {

          position: relative;
          padding-left: 20px;

          &:after {
            content: '';
            height: 5px;
            width: 5px;
            background: #7E7576;
            border-radius: 50%;
            position: absolute;

            right: -12px;
            top: 9px;

          }

          a {
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 1px;
            color: #7E7576;
            font-weight: 300;
            text-transform: uppercase;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }

      }

      p {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #7E7576;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  .footer__copyright {
    margin-top: 80px;
    padding-top: 0px;
    width: 100%;
    position: relative;
    z-index: 3;
    flex-direction: column;
    align-items: center;
    text-align: center;

    a, p {
      font-size: 10px;
      line-height: 16px;
      font-weight: 300;
      color: #7E7576;
      text-transform: uppercase;
      margin: 0 0 5px;
      text-align: center;

      &:last-child {
        margin: 0;
      }
    }
  }


  //responsive 
  @media(max-width: 767px){
    .footer__big-number{
      .justify-content-center{
        align-items: flex-start;
        justify-content: flex-start;
        ul{
          display: block;
          li{
            margin: 0;
            padding: 0;
            &:before, &:after{
              display: none;
            }
          }
        }
        p{
          text-align: left;
          margin: 0;
        }
      }
    }
    .footer__copyright {
      align-items: flex-start;
      margin-top: 40px;
      a,p{
        text-align: left;
      }
    }
    .footer__menu ul{
      align-items: flex-start;
      justify-content: left;
      margin-top: 20px;
    }
    .mobile_menu{
      ul{
        flex-wrap: wrap;
        align-items: unset;
        justify-content: space-between;
        li{
          flex: 0 0 calc(50% - 30px);
          margin: 0 0 20px !important;
          width: calc(50% - 30px);
          
        }
      }
    }
  }

`;

export default MyComponent;
