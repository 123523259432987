import React from 'react';
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import {body_font, hover} from "../styles/globalStyleVars"
import GoogleMapsComponent from "./GoogleMapsComponent";
import Accordions from "./Accordions";

const ContactPersons = ({data}) => {
    return (
        <StyledContactPersons className='contact-person pt-180 pb-180'>
            <Container>
                <Row>
                    <Col md={4}>
                        <h3>{reactHtmlParser(data?.section_data?.title)}</h3>
                    </Col>
                    <Col md={8}>
                        <Accordions data={data?.posts?.list}/>
                    </Col>
                </Row>
            </Container>
        </StyledContactPersons>
    );
};


const StyledContactPersons = styled.section`
  background: #F1EEE9;
  @media(max-width: 767px){
    h3{
      margin-bottom: 40px;
    }
  }
`;


export default React.memo(ContactPersons);