import React, { useContext } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';

import TransitionContext from '../context/TransitionContext';

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  const { toggleCompleted } = useContext(TransitionContext);
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node) => {
          toggleCompleted(false);
          gsap.set(node, { autoAlpha: 0, xPercent: -100 });
          // gsap.set(document?.querySelector('.InnerBanner img'), {autoAlpha:0})
          // gsap.to(node, {
          //   autoAlpha: 1,
          //   duration: 1,
          //   onComplete: () => toggleCompleted(true),
          //   ease: "power1.out",
          // });
          // gsap.to(document?.querySelector('.InnerBanner img'), {
          //   autoAlpha: 1,
          //   duration: 1,
          //   onComplete: () => toggleCompleted(true),
          //   ease: "power1.out",
          // });
          gsap
            .timeline({
              paused: true,
              onComplete: () => toggleCompleted(true),
            })
            .to(node, { autoAlpha: 1, xPercent: 0, duration: 0.25 })
            .to(node, { scale: 1, duration: 0.25 })
            .play();
        }}
        onExit={(node) => {
          gsap
            .timeline({ paused: true })
            .to(node, { xPercent: 100, autoAlpha: 0, duration: 0.2 })
            .play();
          // gsap.to(document?.querySelector('.InnerBanner img'), {
          //   autoAlpha: 0,
          //   duration: 0.4,
          //   ease: "power1.out",
          // });

        }}
      >
        {children}
      </Transition>

    </SwitchTransition>
  );
};

export default TransitionComponent;
