import React from 'react';
import styled from "styled-components";

const MenuClose = () => {
    return (
        <StyledMenuClose>
            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="45" viewBox="0 0 123 45">
                <g id="Group_22585" data-name="Group 22585" transform="translate(-176 -18)">
                    <text id="Close" transform="translate(246 46)" fill="#f1eee9" font-size="15" font-family="MuseoSans-500, Museo Sans" font-weight="500" letter-spacing="0.09em"><tspan x="0" y="0">CLOSE</tspan></text>
                    <g id="Button_-_Nav_-_R" data-name="Button - Nav - R" transform="translate(176 18)">
                        <g id="Base" fill="none" stroke="#f9f5f2" stroke-width="1" opacity="0.3">
                            <rect width="45" height="45" stroke="none"/>
                            <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                        </g>
                        <g id="Hover" fill="none" stroke="#f9f5f2" stroke-width="1" opacity={0} stroke-dasharray="0 200">
                            <rect width="45" height="45" stroke="none"/>
                            <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                        </g>
                        <g id="Group_22584" data-name="Group 22584" transform="translate(-1238.43 -17.5)">
                            <line id="Line_12397" data-name="Line 12397" x1="20" transform="translate(1253.857 32.929) rotate(45)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_12403" data-name="Line 12403" x1="20" transform="translate(1268 32.929) rotate(135)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </g>
                </g>
            </svg>

        </StyledMenuClose>
    );
};

const StyledMenuClose = styled.div`
  line ,#Hover{
    transition: all .4s ease;

  }

  &:hover {
    #Hover{
      opacity: 1;
      stroke-dasharray: 180px 200px;
    }
  }
`;

export default React.memo(MenuClose);
