import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import NavigationIcon from "./NavigationIcon";
import 'swiper/css';
import "swiper/css/pagination";
import 'swiper/css/effect-fade';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";


const CoreValues = (data) => {

    return (
        <StyledComponent className={'our-team  pb-180'}>
            <Container>
                <Row>
                    <Col md={7}>
                        {
                            window.innerWidth <= 768 &&
                            <div  className="title-left">
                                <h2 className={'split-up'}>
                                    {data?.data?.section_data?.title ? reactHtmlParser(data?.data?.section_data?.title) : ''}
                                </h2>
                            </div>
                        }

                        {
                             data?.data?.posts?.list?.length > 0 &&
                            <Swiper

                                effect={"fade"}
                                modules={[Autoplay, Navigation, EffectFade]}
                                navigation={{
                                    prevEl: '#value-prev',
                                    nextEl: '#value-next',
                                }}
                                speed={900}
                            >
                                {
                                    data?.data?.posts?.list?.map((e, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="image-wrapper reveal">
                                                    <Img visible
                                                        src={e?.images?.[0]?.full_path ? e?.images?.[0]?.full_path : ''}/>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })

                                }
                            </Swiper>
                        }


                    </Col>
                    <Col md={5} className={'left-column-wrapper'}>
                        {
                            window.innerWidth > 768 ?
                            <div data-lag={0.2} className="title-left ">
                                <h2 className={'split-up'}>
                                    {data?.data?.section_data?.title ? reactHtmlParser(data?.data?.section_data?.title) : ''}
                                </h2>
                            </div>
                                : ''
                        }


                        <div data-speed="clamp(0.5)" className="slider-wrapper box-up">


                            {
                                data?.data?.posts?.list?.length > 0 &&
                                <Swiper

                                    modules={[Autoplay, Navigation]}
                                    navigation={{
                                        prevEl: '#value-prev',
                                        nextEl: '#value-next',
                                    }}
                                    speed={900}
                                >

                                    {
                                        data?.data?.posts?.list?.map((e, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="content">

                                                        <h4>{e?.data?.title ? reactHtmlParser(e?.data?.title) : ''}</h4>



                                                        <p>{e?.data?.description ? reactHtmlParser(e?.data?.description) : ''}</p>

                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>

                            }

                            <NavigationIcon color={'#F9F5F2'} next_id={'value-next'} prev_id={'value-prev'}/>

                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #f1eee9;
  position: relative;

  .image-wrapper {
    padding-top: calc(768 / 670 * 100%);
    position: relative;
  }


  .left-column-wrapper {
    display: flex;
    position: relative;

    .title-left {
      padding-left: 130px;
      margin-bottom: 90px;

      h2 {
        margin: 0;
      }
    }

    .slider-wrapper {
      position: absolute;
      right: 0;
      max-width: calc(100% + 100px);
      width: calc(100% + 100px);
      z-index: 2;
      bottom: 50px;
      background: #26201E;
      padding: 40px;

      h4 {
        color: #F1EEE9;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 40px;
      }

      p {
        color: #F1EEE9;

      }
    }


  }


  .navigation_button {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }


  
  @media(min-width: 1024px){
    .title-left{
      top:45px;
      position: relative;
    }
  }
  @media (max-width: 768px) {

    .col-md-7, .col-md-5 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .title-left {
      margin-bottom: 45px;
    }

    .left-column-wrapper {
      display: block;
    }

    .slider-wrapper {
      padding: 40px 20px !important;
      position: relative !important;
      right: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .navigation_button {
      margin-top: 40px !important;
      justify-content: flex-start;
    }
  }
`;

export default React.memo(CoreValues);
