import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {body_font, hover, medium, secondary_wood, text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import {Img} from "./Img";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import PlayIcon from "./svg/PlayIcon";
import ModalVideoForGsap from "./ModalVideoForGsap";
import 'react-modal-video/css/modal-video.min.css'

const ConcernBox = ({video, data}) => {


    // video click
    const modalRef = useRef(null);
    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    let [videoId, setVideo] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }

    console.log('data',data)


    return (

        <StyledConcernBox>
            <ModalVideoForGsap show={show} video={true} data={popupId} handleClose={handleClose}/>
            <div className={`${video ? 'hover_video' : ''} wrapper`}>

                {video ?

                    <div onClick={() => handleShow(data?.page_data?.short_desc ? data?.page_data?.short_desc : '5xx_MbmnHCo')}
                         className={'img-wrapper'}>
                        {
                            data?.page_data?.title &&
                            <h5>{reactHtmlParser(data?.page_data?.title)}</h5>
                        }
                        {
                            data?.page_data?.subtitle &&
                            <h6>{reactHtmlParser(data?.page_data?.subtitle)}</h6>
                        }

                        {
                            data?.page_data?.description &&
                            <p>{reactHtmlParser(data?.page_data?.description)}</p>
                        }

                        <div className="image-wrap reveal">
                            <Img
                                src={data?.images?.list[0]?.full_path ? data?.images?.list[0]?.full_path : '/images/static/blurbg.svg'}/>

                            <div className="icon">
                                <PlayIcon/>
                            </div>
                        </div>


                    </div> :

                    <div className={'img-wrapper'}>
                        {
                            data?.page_data?.title &&
                            <h5>{reactHtmlParser(data?.page_data?.title)}</h5>
                        }
                        {
                            data?.page_data?.subtitle &&
                            <h6>{reactHtmlParser(data?.page_data?.subtitle)}</h6>
                        }

                        {
                            data?.page_data?.description &&
                            <p>{reactHtmlParser(data?.page_data?.description)}</p>
                        }

                        <div className="image-wrap reveal">
                            <Img
                                src={data?.images?.list[0]?.full_path ? data?.images?.list[0]?.full_path : '/images/static/blurbg.svg'}/>
                        </div>

                    </div>

                }
            </div>
        </StyledConcernBox>

    )
};


const StyledConcernBox = styled.div`

  .wrapper {
    border-left: 1px solid #26201E;
    padding-right: 65px;

    h5 {
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 1px;
      color: #26201E;
      margin-bottom: 3px;
    }

    h6 {
      color: #7E7576;
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      color: #26201E;
      letter-spacing: 1px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 8; /* For example, limit to 3 lines */
    }

    .image-wrap {
      position: relative;
      overflow: hidden;
      margin-top: 40px;
      padding-top: calc(310 / 310 * 100%);

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        #Ellipse_386-4, #Polygon_2 {
          transition: 0.7s all ease;
        }

      }
    }

    padding-left: 20px;


    &.hover_video {
      cursor: pointer;


      &:hover {
        .image-wrap {
          #Ellipse_386-4 {
            r: 40px;
          }

          #Polygon_2 {
            fill: #f1eee9;
          }
        }
      }

    }
  }


`;

export default React.memo(ConcernBox);














