import React from 'react';
import styled from "styled-components";

const Hamburger = () => {
    return (
        <StyledHamburger>
            <svg className={'hover-svg'} xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15">
                <g id="Group_21060" data-name="Group 21060" transform="translate(-1247.5 -29.5)">
                    <line id="Line_12398" stroke-dasharray={'20'} data-name="Line 12398" x1="20" transform="translate(1248 44)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_12397" stroke-dasharray={'20'} data-name="Line 12397" x1="20" transform="translate(1248 37)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_12396" data-name="Line 12396" x1="20" transform="translate(1248 30)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>
        </StyledHamburger>
    );
};

const StyledHamburger = styled.div`

  line{
    transition: all ease 0.7s;
  }
  &:hover {
    #Line_12397{
      stroke-dasharray:15px;

    }
    #Line_12398{
      stroke-dasharray: 10px;
    }
  }
`;

export default React.memo(Hamburger);
