import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {ImageParallaxNoLoad} from "../ImageParallaxNoLoad";
import {gsap} from 'gsap'
import drawSVG from 'gsap/dist/DrawSVGPlugin'
import Navigatioin from './Navigatioin';

const Banner = ({data, offset}) => {


    // const banner_data = data?.posts?.list;
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);


    // handle slider progress
    const handleProgress = (swiper) => {
        let interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // handle pagination
    let pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const handleSlideChange = (event) => {

        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }


    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };

    useEffect(() => {
        const swiper = swiperRef.current;

        const autoplayDelay = 5000; // Delay in milliseconds before autoplaying

        const autoplayTimeout = setTimeout(() => {
            if (swiper && swiper.autoplay) {
                swiper.autoplay.start();
            }
        }, autoplayDelay);

        return () => {
            clearTimeout(autoplayTimeout);
        };
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsModalVisible(true);
        }, 1500);
    }, [isModalVisible]);

    const data_main = data;


    const lastContentRef = useRef(null);


    gsap.registerPlugin( drawSVG);


    return (
        <>
            <StyledBanner offset={offset} className={'home-banner'}>
                <div className="noise"></div>
                <Container fluid>
                    <Row>
                        <Col md={5} className={'p-0'}>
                            {
                                data_main && data_main?.length > 0 &&

                                <Swiper
                                    ref={swiperRef}
                                    spaceBetween={0}
                                    loop={true}
                                    speed='1500'
                                    onSlideChange={handleSlideChange}
                                    onProgress={handleProgress}
                                    touchStart={handleTouchStart}
                                    onSetTransition={handleSetTransition}
                                    grabCursor={false}
                                    lazy={true}
                                    watchSlidesProgress={true}
                                    mousewheelControl={true}
                                    keyboardControl={true}
                                    navigation={{
                                        prevEl: '#banner-prev',
                                        nextEl: '#banner-next',
                                    }}
                                    pagination={pagination}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    sName="mySwiper main-swiper"
                                >

                                    {
                                        data_main?.length &&
                                        data_main?.map((e, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="image-wrapper ">
                                                        <ImageParallaxNoLoad
                                                            src={e?.images?.[0]?.full_path ? e?.images?.[0]?.full_path : 'images/static/blurbg.svg'}/>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                            }
                        </Col>
                        <Col className={'slider-content'} md={{span: 6, offset: 1}}>
                            {
                                data_main && data_main?.length > 0 &&
                                <Swiper
                                    ref={swiperRef}
                                    spaceBetween={0}
                                    loop={true}
                                    speed='1500'
                                    gap={30}
                                    grabCursor={false}
                                    mousewheelControl={true}
                                    keyboardControl={true}
                                    navigation={{
                                        prevEl: '#banner-prev',
                                        nextEl: '#banner-next',
                                    }}
                                    pagination={pagination}
                                    modules={[Autoplay, Pagination, EffectFade, Navigation]}
                                    sName="mySwiper main-swiper"
                                >
                                    {
                                        data_main?.length &&
                                        data_main?.map((e, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div data-lag={'0.2'} className="inner-content">
                                                        <Title text={e?.data?.title} color={'#F1EEE9'}/>

                                                        <div lastContentRef className="last-content">
                                                            <p>{e?.data?.subtitle ? e?.data?.subtitle : ''}</p>
                                                            {
                                                                e?.data?.short_desc &&
                                                                <Button src={e?.data?.short_desc} margin={'20px 0 0'}
                                                                        color={'#F1EEE9'} no_hover
                                                                        icon_border={'#F1EEE9'}
                                                                        hide_box text={'EXPLORE'}/>
                                                            }
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            }
                        </Col>
                    </Row>
                </Container>

                {
                    data_main && data_main?.length > 0 &&
                    <Navigatioin/>
                }

            </StyledBanner>
        </>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  position: relative;
  opacity: 1 !important;
  overflow: hidden;
  background: #2F374B;
  // overview_bg noise
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #2F374B;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.05;
    }
  }

  .slider-content {
    position: relative;
    padding-right: ${props => props.offset}px;;

    .swiper {
      height: 100vh;

      .inner-content {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .title {
          position: absolute;
          left: 0;
          bottom: 180px;
          opacity: 0;
          transition: all ease 0.7s;

          h2 {
            text-transform: uppercase;
          }
        }

        .last-content {
          position: absolute;
          left: 0;
          bottom: 30px;
          width: 50%;
          opacity: 0;
          transition: all ease 0.7s;

          p {
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            color: #F1EEE9;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }

  .global-image {
    transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active, .swiper-slide-visible {
    .title {
      opacity: 1 !important;
      transition: all ease 0.7s;
      transition-delay: 0.2s;

    }

    .last-content {
      opacity: 1 !important;
      transition: all ease 0.7s;
      transition-delay: 0.2s;

    }
  }


  //navigation
  .navigation {
    position: absolute;
    right: ${props => props.offset ? props.offset + 15 : '90'}px;
    bottom: 75px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    left: ${props => props.offset ? props.offset + 15 : '90'}px;

    .container {
      padding-bottom: 30px;
      border-bottom: 1px solid #F1EEE9;
      max-width: 100%;
    }

    .project-list {
      ul {
        display: flex;
        align-items: flex-start;
        -webkit-box-pack: end;
        justify-content: space-between;
        gap: 30px;
        width: 100%;

        li {
          text-align: center;
          margin: 0;

          p {
            margin: 15px 0 0;
            font-size: 15px;
            font-weight: 500;
            color: #F1EEE9;
            line-height: 22px;
          }
        }
      }
    }

    .navigation_button {
      margin-top: 40px;
    }

  }


  //main slider 
  .swiper-initialized {
    height: 100%;
  }

  .image-wrapper {
    position: relative;
    padding-top: calc(768 / 600 * 100%);
    height: 100%;
  }


  @media (min-width: 1536px) {
    .slider-content {
      .swiper {
        .inner-content {
          .last-content {
            width: 40%;
          }
        }
      }
    }
  }




`;

export default React.memo(Banner);
