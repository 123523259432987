import {createGlobalStyle, css} from 'styled-components';
import {body_font, hover, text, title_font, secondary_wood} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Orchidea Pro ExLt';
    src: url('/fonts/OrchideaPro-Light.woff2') format('woff2'),
    url('/fonts/OrchideaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Orchidea Pro';
    src: url('/fonts/OrchideaPro-Regular.woff2') format('woff2'),
    url('/fonts/OrchideaPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

  }


  @font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/MuseoSans-300.woff2') format('woff2'),
    url('/fonts/MuseoSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/MuseoSans-500.woff2') format('woff2'),
    url('/fonts/MuseoSans-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  body {
     background: #2F374B;
    font-family: ${body_font} !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.47;
    letter-spacing: 1.35px;
    //background: #F1EEE9;
    touch-action: manipulation;
    //font-kerning: none;
    //-webkit-text-rendering: optimizeSpeed;
    //text-rendering: optimizeSpeed;
    //-webkit-transform: translateZ(0);
    //transform: translateZ(0);


    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */


    &.stop-scroll {
      overflow: hidden;
    }
  }

  h1, h2, h3 {
    font-family: ${title_font};
    font-weight: 300;
  }

  h4, h5, h6 {
    font-family: ${body_font};
    font-weight: 500;
  }


  h1 {
    font-size: 3.73rem;
    line-height: 4rem;
    letter-spacing: 1.5px;
  }

  h2 {
    font-size: 2.67rem;
    line-height: 3.47rem;
    letter-spacing: 1px;
  }

  h3 {
    font-size: 2.13rem;
    line-height: 2.67rem;
    letter-spacing: 6px;
  }


  h4 {
    font-size: 1.6rem;
    line-height: 2.13rem;
    letter-spacing: 1.35px;
  }

  h5, h6 {
    font-size: 1.33rem;
    line-height: 2rem;
    letter-spacing: 0;
  }

  /* Paragraph Typography */
  p {
    font-family: ${body_font};
    font-weight: 300;
    font-size: 0.93rem;
    line-height: 1.47;

    &.color {
      color: ${secondary_wood};
    }
  }


  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  a {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }


  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }


  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-180 {
    padding-bottom: 180px;
    @media (max-width: 767px) {
      padding-bottom: 120px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pt-180 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }


  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }
  
  .stop-scroll  .scroll-down.main-menu , .stop-scroll .scroll-up.main-menu , .stop-scroll .main-menu-mobile.scroll-down-mb , .stop-scroll .scroll-down-mb .main-menu-mobile__bar{
    transform: translate3d(0, 0, 0) !important;
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: #2F374B;
    width: 100vw;
    z-index: 999999999999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .log-col{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-wrapper{
      display: inline-block;
      position: relative;
      width: 220px;
      height: 40px;
      
      .cover {
        width: 220px;
        height: 40px;
        background:  #2F374B;
        top: 0;
        left: 0;
        right: 0;
        content: "";
        position: absolute;
        display: flex;
        align-items: center;
        transition: 0.3s all ease;
        text-align: center;
        justify-content: flex-start;
        padding-left: 25px;
        .p1-font{
          color:white;
          text-align: left;
          margin: 0;
        }
      }
      svg{
        height: 40px;
        opacity: 0;
      }
    }

    .container {
      margin: 0;
    }

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.2;
    }

    h3 {
      color: white;
      letter-spacing: 0;
    }

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 1;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2F374B;
    z-index: 999999999;
    //display: none;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 80px;
      opacity: 1;
    }
  }


  //modal


  //modal for bod version 1
  .modal-backdrop {
    //background-color: #fff;
    opacity: 1 !important;
    z-index: 999;
    background-color: rgba(34, 31, 31, 0.9);
    min-width: 100%;
  }

  .modal {
    height: 100vh;
    overflow: hidden !important;
    z-index: 9999999;

    .dc-btn {
      #Contact_Us {
        transform: translate(10px, 15px);
      }
    }

    #Ellipse_18 {
      opacity: 1;
    }

    #Ellipse_19 {
      stroke: ${hover}
    }

    .modal-data__content {
      border-top: 1px solid rgba(60, 60, 59, 0.3);
      padding-top: 40px;
      padding-left: 0;
    }

    &.modal {
      .modal-close {

      }

      .modal-body {
        //padding-top: 50px;
      }
    }

    .modal-body {
      padding: 0;
    }

    .modal-content {
      border: 0;
    }

    .modal-body {
      .modal-close {
        position: relative;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        //top: -30px;
        right: 0px;
        top: 30px;
        overflow: hidden;


        svg {
          #Ellipse_19 {
            transition: 0.7s all ease;
          }
        }

        &:hover {
          svg {
            #Ellipse_19 {
              opacity: 1;
              stroke-dasharray: 142px;
            }
          }
        }
      }

      .hover:after {
        border-radius: 50%;
      }

      .for-close {

        align-items: flex-end;
        justify-content: flex-end;

      }

      .modal-data {
        margin-top: 40px;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        padding-left: 0;

        &__img {

          img {
            object-fit: cover;
            width: 100%;
            margin-bottom: 20px;
          }

          h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
          }

          p {
            color: rgba(60, 60, 59, 0.5);
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
          }
        }

        &__content {
          h4 {
            font-size: 26px;
            line-height: 32px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          ul {

            padding-bottom: 20px;
            border-bottom: 1px solid #221F1F;
            margin-bottom: 40px;

            li {
              position: relative;
              padding-left: 25px;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;

              &:after {
                content: '';
                height: 10px;
                width: 10px;
                background-color: ${hover};
                border-radius: 50%;
                left: 0;
                top: 7px;
                //bottom: 0;
                //margin: auto;
                position: absolute;
              }
            }

          }

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }

        .scroll-div {
          overflow: hidden;
          padding-right: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .modal-dialog {
      width: 90%;
      max-width: unset;
      margin: 1.75rem auto;
      padding: 0;
    }

    .simplebar-track {
      right: -12px;
    }

    .simplebar-track.simplebar-vertical {
      width: 6px;
    }
  }

  @media (max-width: 991px) {
    .modal-dialog {
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    &.modal {
      .modal-close {
        right: 15px !important;
        top: 20px !important;
      }

      .modal-body {
        //padding-top: 50px;
      }
    }

    .modal {
      height: 100% !important;
      overflow-y: auto !important;
      min-height: 100%;

      .col-sm-7 {
        margin: 0;
      }
    }

    .modal-data__img {
      min-width: 100%;

      img {
        max-width: 100%;
      }
    }

    .modal-data__content {
      min-width: 100%;
      margin-top: 40px !important;
    }

    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      //padding: 50px 0;
      padding: 0;
      overflow: hidden;
      height: 100vh;
    }

    .modal-data__content {
      padding-bottom: 60px;
    }

    .modal-body {
      flex: 0;
      padding: 0;

      .modal-data {
        flex-wrap: wrap;
        margin-top: 80px;
      }

      .modal-close {
        right: 15px;
        top: -10px;
      }
    }


  }
  //modal video 
  //video modal
  .modal-video {
    background-color: transparent;
    height: 100vh;
    z-index: 99999;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;

    }

    iframe {
      height: 100%;
      width: 100%;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      position: absolute;
      top: 40px;
      right: 30px;
      cursor: pointer;
      z-index: 99;
      #Ellipse_19, line {
        transition: 0.7s all ease;
      }
      &:hover{
        line{
          stroke: #AC835E;
        }
        #Ellipse_19 {
          opacity: 1;
          stroke-dasharray: 142px;
        }
      }
    }

    @media (max-width: 768px) {
      .modal-content {
        //padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }


  .modal_video_popup {
    .modal-video {
      background: transparent;
    }

    .modal-dialog {
      &:focus-visible {
        outline: none !important;
      }
    }

    .for-close {
    }

    .modal-dialog, .modal-content {
      background: transparent;
    }
  }
  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  //noise keyframe
  @keyframes Noise_grain {
    0%, 100% {
      transform: translate(0);
    }

    10%, 20%, 40%, 60% {
      transform: translate(-5px, -5px);
    }

    30%, 50% {
      transform: translate(-10px, -5px);
    }

    70% {
      transform: translateY(0);
    }

    80% {
      transform: translate(-15px, -15px);
    }

    90% {
      transform: translate(-15px, 15px);
    }
  }


  //swipper disable 
  .swiper-button-disabled {
    opacity: 0.5 !important;
  }


  //menu fixed
  .scroll-down.main-menu {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up.main-menu {
    transform: translate3d(0, 0, 0);
    backdrop-filter: blur(5px);
    background: rgba(38, 32, 30, 0.4);
  }

  .scroll-down-mb {
    transform: translate3d(0, -100%, 0);

  }

  .scroll-down-mb .main-menu-mobile__bar {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up-mb {
    transform: translate3d(0, 0, 0);

  }

  .scroll-up-mb .main-menu-mobile__bar {
    backdrop-filter: blur(5px);
    background: rgba(38, 32, 30, 0.4);
  }

  .scroll-down .menu-desktop {
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }


  .pointer {
    cursor: pointer;
  }


  //modal for team popup 
  .popup-version-one {
    padding-left: 0 !important;

    .modal-dialog {
      transform: none;
      margin: 0 0 0 auto;
      width: 55%;
      border-radius: 0;

      .modal-content {
        border: 0px;
        border-radius: 0;
        background: transparent;

        .container {
          max-width: 100%;
        }

        .noise {
          position: absolute;
          inset: 0;
          overflow: hidden;
          width: 100%;
          height: 100%;
          background: #26201E;

          &:after {
            content: '';
            background-image: url('/images/static/background-noise.png');
            background-blend-mode: multiply;
            //animation: Noise_grain 5s infinite linear;
            //width: calc(100% + 500px);
            //height: calc(100% + 500px);
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 0.2;
          }

          &:after {
            //animation: Noise_grain 5s infinite linear;
          }


        }

        .modal-close {
          height: auto;
          width: auto;
          border-radius: 0;
          right: 30px;
        }

        .modal-data__content {
          border: none;
        }

        .modal-main-content {
          height: calc(100vh - 80px);
          overflow-y: scroll;
          padding-bottom: 90px !important;
          padding-left: 100px !important;
          padding-right: 100px !important;

          &::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          .profile-info {

            .img-wrp {
              img {
                height: 270px;
              }
            }

            h5 {
              color: #F1EEE9;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-top: 20px;
            }

            p {
              color: #7E7576;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              margin: 0;
            }
          }

          .profile-details {
            margin-top: 40px;

            p {
              color: #F1EEE9;
              font-size: 15px;
              font-weight: 300;
              margin: 0;
            }
          }
        }
      }
    }
  }

  //responsive for team popup
  @media (max-width: 767px) {
    .popup-version-one {
      .modal-dialog {
        width: 100%;

        .modal-content {
          .modal-main-content {
            padding-left: 15px !important;
            padding-right: 15px !important;

            .profile-info {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .popup-version-one .modal-dialog .modal-content .modal-main-content .profile-info .img-wrp img{
      height: 100%;
    }
  }


  //career modal 
  .popup-version-one.CareerModal {
    padding: 0;
    overflow: hidden;
    
    .modal-dialog {
      transform: none;
      margin: 0 0 0 auto;
      width: 100%;
      border-radius: 0;

      .container {
        max-width: 90%;
      }

      .modal-content {
        border-radius: 0;
        background: transparent;

        .noise {
          position: absolute;
          inset: 0;
          overflow: hidden;
          width: 100%;
          height: 100%;
          background: #26201E;

          &:after {
            content: '';
            background-image: url('/images/static/background-noise.png');
            background-blend-mode: multiply;
            //animation: Noise_grain 5s infinite linear;
            //width: calc(100% + 500px);
            //height: calc(100% + 500px);
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 0.2;
          }

          &:after {
            //animation: Noise_grain 5s infinite linear;
          }


        }

        .modal-close {
          height: auto;
          width: auto;
          border-radius: 0;
          right: 30px;
        }

        .modal-data__content {
          border: none;
        }

        .modal-main-content {
          height: calc(100vh - 80px);
          overflow-y: scroll;
          padding-bottom: 90px !important;

          &::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          .profile-info {
            h5 {
              color: #F1EEE9;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-top: 20px;
            }

            p {
              color: #7E7576;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              margin: 0;
            }
          }

          .profile-details {
            margin-top: 40px;

            p {
              color: #F1EEE9;
              font-size: 15px;
              font-weight: 300;
              margin: 0;
            }
          }
        }
      }

      .modal-content-left {
        h5 {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          color: #F1EEE9;
          text-transform: uppercase;
          padding-bottom: 40px;
          border-bottom: 1px solid rgba(241, 238, 233, 0.2);
        }

        .inner-content {
          margin-top: 40px;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: #F1EEE9;
            margin-bottom: 40px;
          }

          p {
            color: #F1EEE9;
            font-size: 15px;
            font-weight: 300;
          }

          ul {
            li {
              font-size: 15px;
              font-weight: 300;
              color: #F1EEE9;
              position: relative;
              padding: 0px 0px 20px 40px;
              z-index: 50;
              position: relative;
              counter-increment: count 1;
              border-bottom: 1px solid rgba(241, 238, 233, 0.2);
              margin-bottom: 20px;

              &:before {
                content: counter(count, decimal);
                position: absolute;
                height: 18px;
                width: 18px;
                margin: 0px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                border: 1px #F1EEE9;
                background-color: #F1EEE9;
                border-radius: 50%;
                color: #26201E;
                top: 2px;
                left: 0px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
              }

              &:last-child {
                border: none;
              }
            }
          }
        }
      }

      .modal-data {
        display: flex !important;
        height: calc(100vh - 90px);
        overflow-y: scroll;


        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }


      .form-wrapper-all {
        .form-control {
          margin-bottom: 40px;
          background: transparent;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid rgba(241, 238, 233, 0.5);
          padding-left: 0;
          padding-bottom: 15px;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          color: rgba(241, 238, 233, 1);
        }


        .formContact {


          input {
            &:not(.react-select__input input) {
              line-height: 20px;
              border-radius: 0;
              height: 44px;
              border-color: rgba(255, 255, 255, 0.5);
            }

            padding-left: 0;
            padding-right: 0;

            &::placeholder {
              color: rgba(241, 238, 233, 0.5);
            }
          }

          .react-select__menu {
            z-index: 9;
          }

          .react-select__control--menu-is-open {

          }

          .react-select__indicators {
            display: block;

            .react-select__indicator {
              padding-right: 0;
              padding-left: 0;
              color: white !important;
            }
          }

          .react-select__value-container {
            padding: 0;
            display: block;
          }

          .react-select__single-value {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(255, 255, 255, 1);
          }

          textarea {
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            min-height: 100px;
          }

          .form-select {
            .react-select__indicator-separator {
              display: none;
            }

            .react-select__control {
              //height: 48px;
              border-color: rgba(255, 255, 255, 0.5);
              box-shadow: none;
              border-left: none;
              border-top: none;
              border-right: none;
              background: transparent;
              height: 30px;
              margin-bottom: 40px;
            }

            .react-select__placeholder {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.5);
            }
          }

        }


        .max_size {
          font-size: 10px;
          font-weight: 300;
          line-height: 20px;
          color: rgba(34, 34, 34, 0.8);
          margin: 5px 0 0;
        }

        .gph_upload {
          position: relative;
          cursor: pointer;
          border: none !important;
          border-radius: 25px !important;
          overflow: hidden !important;

          &.hide {
            &:before {
              opacity: 0;
            }
          }

          &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            content: attr(text);
            border: 1px #FFFFFF;
            border-radius: 25px;
            border-style: dashed;
            background: #3C3C3B;
            z-index: 1;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.7s all ease;
            padding-left: 20px;
          }

          &:before {
            content: '';
            background-image: url('images/static/attach.svg');
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            z-index: 11;
            left: 25%;
            top: 10px;
          }

          @media (min-width: 1024px) and (max-width: 1280px) {
            &:after {
              padding-left: 20px;
            }

            &:before {
              left: 23%;
            }
          }
          @media (min-width: 1536px) {
            &:before {
              left: 30%;
            }
          }

          &:hover {
            &:after {
              border: 1px ${hover};
              border-radius: 25px;
              background: ${hover};
            }
          }
        }

        .has-error {
          border-color: #EE1B24 !important;
        }
      }


    }
  }


  #smooth-content {
    will-change: transform;
  }


  .modal_video_popup {
    .modal-dialog {
      width: 100%;
    }
  }


  //toastify
  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999;
  }


  .loader-bar {
    height: 100%;
    width: 100%;
    padding-top: 60px;
    transition: width 0.2s ease; /* Adjust the transition duration as needed */
    background-color: transparent; /* Loader bar color */
    color: #fff; /* Text color */
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-direction: column;

    .bar {
      width: 100%;
      background: white;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      position: absolute;
      //transition: 0.8s all ease-in-out;

    }
   
    &:after {
      width: 100%;
      background: white;
      height: 2px;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      position: absolute;
    }
  }

`;



