import React, {useEffect} from 'react';
import styled from "styled-components";
import {
    useParams
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPostDetail} from "../../api/redux/projects";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import AtaGlance from "../../components/project/AtaGlance";
import OverviewInner from "../../components/project/OverviewInner";
import FeatureAmenities from "../../components/project/FeatureAmenities";
import Video from "../../components/Video";
import Gallery from "../../components/Gallery";
import MapForm from "../../components/project/MapForm";
import SuccessSlider from "../../components/SuccessSlider";
import {ApiParam} from "../../api/network/apiParams";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {SplitText} from "gsap/SplitText";
gsap.registerPlugin(SplitText, CSSPlugin);

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.projectsReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let all_product = apiEndPoints.PRODUCT_DATA
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: slug,
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispatch(fetchPostDetail([all_product, param]))
    }, [dispatch])

    // details = getData?.detail?.data?.posts?.listfind(element => element?.product_data?.slug === slug);
    let banner = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'inner-banner');
    let ataglance = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'at-a-glance');
    const overview = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'overview');
    const features = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'features-amenities');
    const video = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'video-section');
    const gallery = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'gallery');
    const success = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'our-success-stories');
    const map = getData?.detail?.data?.posts?.list?.find(element => element?.data?.slug === 'maps');


    // for close menu
    let tl2 = new TimelineLite();
    useEffect(() => {
        document?.querySelector('.main-menu')?.classList.remove('menu-is-open')
        document.body.classList.remove('stop-scroll')
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })



        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }

    }, [getData,dispatch])




    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.detail?.data?.product_data?.title ? getData?.detail?.data?.product_data?.title+' - Formonic Design & Construction' : 'Formonic Design & Construction'}</title>
                {
                    getData?.detail?.data?.product_data?.meta_description &&
                    <meta name="description" content={getData?.detail?.data?.product_data?.meta_description}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_title &&
                    <meta property="og:title" content={getData?.detail?.data?.product_data?.og_title}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_description &&
                    <meta property="og:description" content={getData?.detail?.data?.product_data?.og_description}/>

                }
            </Helmet>

            <StyledComponent className={video ? "" : "video-section-none"}>
                <InnerBanner project title={getData?.detail?.data?.product_data?.title} subtext={getData?.detail?.data?.product_data?.location}
                             uptitle={getData?.detail?.data?.product_data?.type_title} img={banner?.images?.[0]?.full_path}/>
                {
                    ataglance &&
                    <AtaGlance data={ataglance}/>
                }

                {
                    overview &&
                    <OverviewInner data={overview}/>
                }

                {
                    features &&
                    <FeatureAmenities data={features}/>
                }
                {
                    video &&
                    <Video data={video}/>
                }
                {
                  gallery &&
                  <Gallery gap={ video ?  true: false} data={gallery}/>
                }

                {
                    getData?.detail?.success_story?.list &&
                  <SuccessSlider data={getData?.detail?.success_story?.list}/>

                }
                {
                    map &&
                    <MapForm data={map} title={getData?.detail?.data?.product_data?.title} lat={parseFloat(getData?.detail?.data?.product_data?.latitude)} long={parseFloat(getData?.detail?.data?.product_data?.longitude)}/>
                }

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  background: #F1EEE9;

  .video-section {
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    position: relative;
  }
    &.video-section-none{
        .gallery{
            padding-top: 180px !important;
            margin-top: 0 !important;
            @media(max-width: 767px){
                padding-top: 100px !important;
            }
        }
        
        .feature{
            padding-bottom: 180px !important;
            @media(max-width: 767px){
                padding-bottom: 100px !important;
            }
        }
    }
  .gallery{
    margin-top: -100px;
  }
  
  
  @media(max-width: 767px){
    .video-section {
      top: 0;
      transform: translateY(0);
      z-index: 4;
      position: relative;
    }
    .gallery{
      padding-top: 100px;
      margin-top: 0;
    }
  }
`;

export default MyComponent;
