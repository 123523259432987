import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import CloseModal from "./svg/CloseModal";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Button from '../components/Button';
import {gsap, TimelineLite} from "gsap";
import reactHtmlParser from "react-html-parser";
import {postForm} from "../api/redux/projects";
import {apiEndPoints} from "../api/network/apiEndPoints";

const PeoplePopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    // all from popup
    const dispatch = useDispatch()
    const formData = '';
    const [validPosition, setValidPosition] = useState(false)
    const [uploadText, setUploadText] = useState('Attach CV')

    const DropdownOption = [
        {value: 'Front-end Developer', label: 'Front-end Developer'},
        {value: 'WordPress Developer', label: 'WordPress Developer'},
        {value: 'DevOps Developer', label: 'DevOps Developer'},

    ];


    const responseData = useSelector(state => state.careerReducer);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = async (e) => {

        let api_services = apiEndPoints.SUBMITFORM;
        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('cover_letter', e?.cover_letter);
        formData.append('file', cv);
        formData.append('form_id', 'career-form');
        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            reset();
            try {
                const response = await dispatch(postForm([api_services, formData]));

                if (response && response.payload.result === 'success') {
                    success(response.payload.message);
                } else if (response && response.error && response.error) {
                    error(response.payload.message);
                }
            } catch (err) {
                console.error('API Error:', err); // Check for any errors in the console
                error('An error occurred while submitting the form.');
            }
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };


    const [cv, setCv] = useState(null)
    const cvRef = useRef()



    // handleUpload function
    function handleUpload(event) {
        const selectedFile = event.target.files[0];
        setCv(event.target.files[0]);
        if (selectedFile) {
            const fileName = selectedFile.name;
            setUploadText(fileName)
            document.querySelector('.gph_upload').classList.add('hide')

        }

    }


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one CareerModal"
            >
                <div className="noise"></div>

                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <CloseModal/>
                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data modal-content-left d-flex">
                                    <Col sm={7} className=" ">
                                        <h5>{reactHtmlParser(data?.data?.title)}</h5>

                                        <div className="inner-content">
                                            {reactHtmlParser(data?.data?.description)}
                                        </div>
                                    </Col>
                                    <Col md={{span: 5, offset: 1}}>
                                        <div className="form-wrapper-all">
                                            <Form onSubmit={handleSubmit(onSubmit, onError)} method="post"
                                                  enctype="multipart/form-data">
                                                <Row>
                                                    <input name={'spam_protector'} type='hidden'/>
                                                    <input name={'form_id'} value={'career-form'} type='hidden'/>

                                                    <Form.Group className="col-md-12 pb-30">

                                                        <Form.Control
                                                            className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("name", {
                                                                required: 'Name is required',
                                                                pattern: {
                                                                    message: 'Name must contain only letters',
                                                                },

                                                            })}
                                                            type="text"
                                                            placeholder="First Name*"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-md-12 pb-30">
                                                        <Form.Control
                                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("email", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'please enter your email'
                                                                },
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: 'please enter a valid email address'
                                                                }
                                                            })}
                                                            type="email"
                                                            placeholder="Email*"/>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-12 pb-30">
                                                        <Form.Control
                                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("phone", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'please enter your phone first'
                                                                },
                                                                pattern: {
                                                                    value: /^01[0-9]{9}$/,
                                                                    message: 'please enter a valid 11 digit phone number'
                                                                }
                                                            })}
                                                            type="number"
                                                            placeholder="Phone Number*"/>
                                                    </Form.Group>
                                                    <div className="clear"></div>


                                                    <Form.Group className="col-md-12 pb-30">
                                                        <textarea
                                                            className={formState?.errors?.cover_letter?.message ? 'has-error form-control form-control-lg' : 'form-control form-control-lg'}
                                                            {...register("cover_letter", {
                                                                required: {
                                                                    // value:true,
                                                                    message: 'please enter your Message'
                                                                },

                                                            })}
                                                            type="text"
                                                            placeholder="Cover Letter"/>

                                                    </Form.Group>
                                                    <Form.Group className="col-xl-6 pb-30">


                                                        <Form.Control
                                                            ref={cvRef}
                                                            onInput={handleUpload}
                                                            className={'gph_upload'}
                                                            text={uploadText}
                                                            type="file"
                                                            accept=".pdf"
                                                            {...register('file')}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <div className={`form-group width-fit`}>
                                                    <div onClick={handleSubmit(onSubmit, onError)}>

                                                        <Button src={'#'} color={'#F1EEE9'} icon_border={'#F1EEE9'}
                                                                text={'Submit Query'}/>
                                                    </div>

                                                </div>
                                            </Form>

                                        </div>
                                    </Col>


                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(PeoplePopup);