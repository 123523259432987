import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Modal from "react-bootstrap/Modal";
import PlayIcon from "./svg/PlayIcon";


const Video = ({data}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        console.log(show, 'click')
    }

    return (
        <StyledVideo className={`video-section`} >
            {/*modal*/}
            <Modal className={'modal-video'} show={show} onHide={handleClose}>


                <svg className={'close-modal'} onClick={handleClose} id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                    <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#fff" stroke-width="1" opacity="0.3">
                        <circle cx="22" cy="22" r="22" stroke="none"/>
                        <circle cx="22" cy="22" r="21.5" fill="none"/>
                    </g>
                    <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" strokeDasharray="0 142" opacity={'0'}>
                        <circle cx="22" cy="22" r="22" stroke="none"/>
                        <circle cx="22" cy="22" r="21.5" fill="none"/>
                    </g>
                    <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                        <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                        <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                    </g>
                </svg>


                <Modal.Body>
                    <iframe width="560" height="315"
                            src={`https://www.youtube.com/embed/${data?.data?.subtitle}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Modal.Body>

            </Modal>
            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}} className={'p-0'}>
                        <div onClick={handleShow} className="img-wrapper">
                            <Img src={data?.images[0]?.full_path}/>
                            <div className="icon">
                                <PlayIcon/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledVideo>

    )
};

const StyledVideo = styled.div`

  .img-wrapper {
    position: relative;
    padding-top: calc(600 / 1030 * 100%);
    overflow: hidden;
    cursor: pointer;
    #Ellipse_386-4, #Polygon_2 {
      transition: 0.7s all ease;
    }


    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      #Ellipse_386-4, #Polygon_2 {
        transition: 0.7s all ease;
      }

    }
    
    &:hover{
      #Ellipse_386-4 {
        r: 40px;
      }

      #Polygon_2 {
        fill: #f1eee9;
      }
    }
  }
`;


export default React.memo(Video);
