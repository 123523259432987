import React from 'react';
import styled from "styled-components";

const PlayIcon = () => {
    return (
        <StyledPlay>
            <svg xmlns="http://www.w3.org/2000/svg"  width="140" height="140"
                 viewBox="0 0 140 140">
                <g id="Button_-_Video" data-name="Button - Video" transform="translate(30 30)">
                    <g transform="matrix(1, 0, 0, 1, -30, -30)" filter="url(#Ellipse_386)">
                        <circle id="Ellipse_386-2" data-name="Ellipse 386" cx="40" cy="40" r="40"
                                transform="translate(30 30)" fill="#f1eee9"/>
                        <circle id="Ellipse_386-4" data-name="Ellipse 386" cx="40" cy="40" r="0"
                                transform="translate(30 30)" fill="#2f374b"/>
                    </g>

                    <path id="Polygon_2" data-name="Polygon 2" d="M12,0,24,18H0Z"
                          transform="translate(52 28) rotate(90)" fill="#2f374b"/>
                </g>
            </svg>
        </StyledPlay>
    );
};

const StyledPlay = styled.div`

`;

export default React.memo(PlayIcon);
