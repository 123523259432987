import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";


const Feature = ({data}) => {

    const title = data?.title;
    const features = data?.features;



    const settings = {
        dots: true,
        rows: 2,
        slidesPerRow: 1,
        infinite: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <StyledComponent id={'features'} className='feature pt-180 '>
            <div className="noise"></div>
            <Container>
                <Row>
                    <Col md={12} className='text-center'>
                        <Title color={'#F1EEE9'} margin={'0 0 90px'} text={reactHtmlParser(data?.data?.title)} />
                    </Col>
                </Row>


                <div className="slider" style={{width: '100%'}}>
                    {
                        data?.images &&
                        <Slider {...settings}>
                            {
                                data?.images?.length>0 && data?.images?.map((e,i)=>{
                                    return(
                                        <div className='feature__single' key={i}>
                                            <div className='feature__single__img'>
                                                <img src={e?.full_path}/>
                                            </div>
                                            <div>
                                                <h4>{e?.short_title}</h4>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    }

                </div>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  padding-bottom: 480px;
  // overview_bg
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #2F374B;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      //animation: Noise_grain 5s infinite linear;
      //width: calc(100% + 500px);
      //height: calc(100% + 500px);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.3;
    }

    &:after {
      //animation: Noise_grain 5s infinite linear;
    }


  }

  .row {
    justify-content: center;
  }

  .shadow-right {
    position: absolute;
    top: 0;
    right: -145px;
    height: fit-content;
  }


  .col {
    padding-left: 0px;
    padding-right: 0px;
  }

  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .feature {

    &__title {
      display: flex;
      justify-content: center !important;
      align-items: center;
      margin-bottom: 42px;

      h2 {
        @media (max-width: 767px) {
          font-size: 40px !important;
          line-height: 40px !important;
          //margin-top: 40px;
        }
      }
    }

    &__single {
      //padding: 40px 30px 57px 30px;
      background-color: transparent;
      padding-top: 100%;
      position: relative;

      h4 {
        font-size: 15px;
        line-height: 22px;
        font-weight: 300;
        text-align: center;
        //height: 44px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        //text-overflow: ellipsis;
        position: absolute;
        top: 135px;
        left: 20px;
        right: 20px;
        color: #F1EEE9;
        text-transform: uppercase;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        margin: auto;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }

    }
  }

  .slick-dots li{
    width: 30px;
    margin: 0 15px;
    height: 5px;

    button{
      width: 30px;
      height: 5px;

    }
    
    &.slick-active{
      width: 65px;
      height: 5px;

    }
  }
 
  .slick-dots li.slick-active button::before {
    background-color: #F1EEE9;
    border-color: transparent;
    height: 5px;
    width: 65px;
  }

  @media (max-width: 991px) {
    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 50%;
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 120px !important;
    .feature__single__img {
      top: 10px;
    }

    .feature__single h2 {
      top: 110px;
    }
  }

  .slick-track {
    display: flex !important;
    height: 100%;
  }

  .slick-slide {
    height: auto;

    .slick-slide > div {
      height: 100%;

      .myItemClass {
        height: 100%;
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    border-right: 1px solid rgba(241, 238, 233, 0.1);
    border-top: 1px solid rgba(241, 238, 233, 0.1);
    border-bottom: 1px solid rgba(241, 238, 233, 0.1);

    > div:nth-of-type(1) {
      border-bottom: 1px solid rgba(241, 238, 233, 0.1);
    }

    &:nth-of-type(1) {
      border-left: 1px solid rgba(241, 238, 233, 0.1);
    }

    @media (max-width: 767px) {
      &:nth-of-type(1) {
        border-left: 0;
      }
    }
  }


  .slick-dots{
    bottom: -65px;
  }

  .slick-dots li button::before {
    color: transparent;
    border: none;
    opacity: 1;
    border-radius: 4px;
    height: 5px;
    width: 30px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    background: #7E7576;
  }
  
  
  @media (max-width: 767px) {
    margin-left: -15px;
    margin-right: -15px;
    .title {
      margin-bottom: 0;
    }
  }


`;
export default Feature;